import { Button, CheckBox, Form, Gallery, NumberBox, Popup, ValidationGroup, Validator } from 'devextreme-react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { TabPanel, Item as TabPanelItem } from 'devextreme-react/tab-panel';
import {
    AsyncRule,
    CustomRule,
    GroupItem, Label, NumericRule, PatternRule, RangeRule, SimpleItem, StringLengthRule, ValidationRule
} from 'devextreme-react/form';

import React, { Component } from 'react';
import { apiDelete, apiGet, apiPost, apiPut, toLowerKeys } from '../../GanjiTools';
import TarikhPicker from '../TarikhPicker';
import { UniGrid } from '../UniGrid';
import { DateObject } from 'react-multi-date-picker';
import { EmailRule } from 'devextreme-react/data-grid';
import UniPopup from '../UniPopup';
import Uploader from '../Uploader.js';
import UrlImage from '../UrlImage';
//import ConfirmToSave from '../permit/ConfirmToSave';

const imageStyle = {
    height: '15rem',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
    minWidth: '50px',
    minHeight: '50px',
    maxWidth: '40rem',
};

class UserInfo extends Component {
    state = {
        gridKey: 1,
        imageUploadingField: 'pic',
        imageKeyCounter: 1,
        tabPanelKeyCounter: 1,
        user: this.props.user,
        personCodeKey: 1,
        personCodeError: !(this.props.user?.personcode?.length > 0) ? 'کد پرسنلی خالی است' : '',
        personCodeIsRepeated: false,
    };

    checkImageExists = path =>
        new Promise(resolve => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = path;
        });


    onClickSaveButton = e => {
        if (this.state.personCodeError?.length > 0) {
            window.notif('کد پرسنلی نامعتبر یا تکراری است');
            return;
        }

        let vres = this.validationGroup?.instance?.validate();
        console.log(vres);
        if (!vres.isValid) {
            window.notif('اشکالات موجود در فرم را برطرف کنید');
            vres.brokenRules?.forEach(x => window.notif(x.message));
            return;
        }
        //this.setState({ rowPopupVisible: false });
        apiPut('/api/userAccess/updateUser', this.state.user,
            res => {
                window.notif(res.error?.length > 0 ? res.error : 'اطلاعات با موفقیت ذخیره شد');
                if (res.error?.length > 0) {
                    console.log('#save error: ' + res.error);
                    this.setState({ gridKey: this.state.gridKey + 1 }, this.props.onSaveFailed);
                }
                else if (this.state.user?.id === 0)
                    this.setState({ gridKey: this.state.gridKey + 1 }, this.props.onSaved);
            },
            err => this.props.onSaveFailed?.(err)
        );
    }

    // showUserPopup = id => {
    //     // if (this.usersGrid)
    //     //     this.usersGrid.userKey = id;
    //     let user = this.usersGrid?.state?.rows?.find(x => x.id === id);
    //     if (!(user?.id > 0))
    //         return;

    //     this.usersGrid?.props?.onuserChanged(user)
    //     this.setState({ user, rowPopupVisible: true });
    // }

    getUserJobs = userRow => {
        userRow ??= this.state.user;
        if (!userRow)
            return [];

        let jobs = [];
        if (userRow.permitjob1id > 0)
            jobs.push({ id: userRow.permitjob1id, title: userRow.permitjob1name });
        if (userRow.permitjob2id > 0)
            jobs.push({ id: userRow.permitjob2id, title: userRow.permitjob2name });
        if (userRow.permitjob3id > 0)
            jobs.push({ id: userRow.permitjob3id, title: userRow.permitjob3name });

        return jobs;
    }

    checkPersonCodeUniqueness = whenDone => {
        if (!(this.state.user?.personcode?.length > 0)) {
            //window.notif('کد پرسنلی نباید خالی رها شود');
            this.setState({ personCodeIsRepeated: true, personCodeKey: this.state.personCodeKey + 1 }, whenDone);
            return;
        }

        if (this.state.user.personcode === '1111') {
            this.setState({ personCodeIsRepeated: false, personCodeKey: this.state.personCodeKey + 1 }, whenDone);
            return;
        }

        apiGet('/api/userAccess/idByPersonCode/' + this.state.user.personcode,
            res => {
                if (res.error?.length > 0) {
                    window.notif(res.error);
                    this.setState({ personCodeIsRepeated: true, personCodeKey: this.state.personCodeKey + 1 }, whenDone);
                    return;
                }
                if (res.id > 0 && res.id !== this.state.user?.id) {
                    window.notif('کد پرسنلی تکراری است');
                    this.setState({ personCodeIsRepeated: true, personCodeKey: this.state.personCodeKey + 1 }, whenDone);
                    return;
                }
                this.setState({ personCodeIsRepeated: false, personCodeKey: this.state.personCodeKey + 1 }, whenDone);
            },
            err => {
                window.notif(err);
                this.setState({ personCodeIsRepeated: true, personCodeKey: this.state.personCodeKey + 1 }, whenDone);
            }
        );
    }

    checkPersonCodeValidity = whenDone => {
        let len = this.state.user?.personcode?.length ?? 0;
        if (len < 1 || len > 10) {
            this.setState({ personCodeError: 'کد پرسنلی باید عددی بین 1 تا 10 رقمی باشد', personCodeKey: this.state.personCodeKey + 1 }, whenDone);
            return;
        }

        if (!/^[0-9]*$/.test(this.state.user.personcode)) {
            this.setState({ personCodeError: 'کد پرسنلی باید عدد باشد', personCodeKey: this.state.personCodeKey + 1 }, whenDone);
            return;
        }

        this.checkPersonCodeUniqueness(() => {
            if (this.state.personCodeIsRepeated) {
                this.setState({ personCodeError: 'کد پرسنلی تکراری است', personCodeKey: this.state.personCodeKey + 1 }, whenDone);
                return;
            }
            this.setState({ personCodeError: '', personCodeKey: this.state.personCodeKey + 1 }, whenDone);
        });
    }

    componentDidMount() {
        this.checkPersonCodeValidity();
    }

    renderBasicInfo = () => (
        <div key={'frm' + this.state.user?.id} style={{ padding: '2rem' }}>
            <ValidationGroup ref={el => this.validationGroup = el}>
                <Form colCount={2} showColonAfterLabel={true} rtlEnabled={true} //height={405}
                >
                    <GroupItem>
                        <SimpleItem key={'persc' + this.state.personCodeKey}>
                            <Label style={{ fontWeight: '900' }}>کد پرسنلی</Label>
                            <TextBox rtlEnabled={true} value={this.state.user?.personcode}
                                readOnly={!this.props.adminMode} // || !this.state.personCodeIsRepeated}
                                placeholder='کد پرسنلی جدید را وارد کنید'
                                //key={'perst' + this.state.personCodeKey}
                                onValueChanged={e => {
                                    this.state.user.personcode = e.value;
                                    //if (!(this.state.user?.id <= 0))
                                    this.checkPersonCodeValidity();
                                }}
                            >
                                <Validator ref={el => this.personCodeValidator = el}>
                                    <CustomRule validationCallback={v => {
                                        v.rule.message = this.state.personCodeError;
                                        return !v.rule.message;
                                    }}
                                    />
                                </Validator>
                                {!(this.state.user?.id > 0) && !this.state.personCodeError &&
                                    <TextBoxButton name='save' location='after'
                                        options={{
                                            icon: 'save',
                                            type: 'default',
                                            hint: 'با زدن این دکمه ویرایش همه موارد ممکن می شود',
                                            onClick: () => {
                                                if (this.state.user?.id > 0 || !(this.state.user?.personcode?.length > 0))
                                                    return;

                                                apiPut('/api/userAccess/updateUser',
                                                    { id: 0, personcode: this.state.user.personcode },
                                                    res => {
                                                        if (res.error?.length > 0) {
                                                            window.notif(res.error);
                                                            this.setState({
                                                                personCodeIsRepeated: true,
                                                            });
                                                            return;
                                                        }
                                                        this.state.user.id = res.id;
                                                        this.setState({
                                                            //tabPanelKeyCounter: this.state.tabPanelKeyCounter + 1,
                                                            personCodeIsRepeated: false
                                                        });
                                                    },
                                                    err => window.notif(err)
                                                );
                                            },
                                        }}
                                    />
                                }
                                {/* {false && <Validator ref={el => this.personCodeValidator = el}>
                                    <StringLengthRule message='کد پرسنلی باید بین یک تا ده رقم باشد' min={1} max={10} />
                                    <PatternRule pattern='^[0-9]*$' message='کد پرسنلی فقط شامل ارقام است' />
                                </Validator>
                                } */}
                            </TextBox>
                        </SimpleItem>
                        <SimpleItem>
                            <Label>نام</Label>
                            <TextBox rtlEnabled={true} value={this.state.user?.fname}
                                readOnly={!this.props.adminMode}// || !(this.state.user?.id > 0)}
                                onValueChanged={e => {
                                    this.state.user.fname = e.value;
                                    this.forceUpdate();
                                }}
                            />
                        </SimpleItem>
                        <SimpleItem>
                            <Label>نام خانوادگی</Label>
                            <TextBox rtlEnabled={true} value={this.state.user?.lname}
                                readOnly={!this.props.adminMode}// || !(this.state.user?.id > 0)}
                                onValueChanged={e => {
                                    this.state.user.lname = e.value;
                                    this.forceUpdate();
                                }}
                            />
                        </SimpleItem>
                        <SimpleItem>
                            <Label>نام پدر</Label>
                            <TextBox rtlEnabled={true} value={this.state.user?.fathername}
                                readOnly={!this.props.adminMode}// || !(this.state.user?.id > 0)}
                                onValueChanged={e => {
                                    this.state.user.fathername = e.value;
                                    this.forceUpdate();
                                }}
                            />
                        </SimpleItem>
                    </GroupItem>

                    <GroupItem>
                        <SimpleItem>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {/* <img src={`/images/user-profile/${this.state.userImageExists ? this.state.user?.id ?? 0 : 0}.png`}
                                    className='ka-shaddowed'
                                    style={{
                                        height: '10rem',
                                        objectFit: 'cover'
                                    }}
                                    onClick={e => this.setState({ imageUploadingPopupVisible: true })}
                                /> */}
                                <UrlImage key={'pic' + this.state.imageKeyCounter + this.props.sid}
                                    url={this.state.user?.id > 0 && `/api/userAccess/image/${this.state.user?.id}/pic`}
                                    errorImage='/images/user-profile/0.png' dontCache
                                    sid={'pic_' + this.state.user?.id + '_' + this.props.sid}
                                    onClick={e => !(this.state.user?.id > 0)
                                        ? window.notif('ابتدا کد پرسنلی را مشخص کنید')
                                        : this.setState({
                                            imageUploadingPopupVisible: true,
                                            imageUploadingField: 'pic'
                                        })}
                                    className='ka-shaddowed'
                                    style={{ ...imageStyle, height: '10rem' }}
                                />
                            </div>
                        </SimpleItem>
                    </GroupItem>

                    <GroupItem colCount={2} colSpan={2}>
                        <SimpleItem>
                            <Label>کد ملی</Label>
                            <TextBox rtlEnabled={true} readOnly={!this.props.adminMode}// || !(this.state.user?.id > 0)}
                                value={this.state.user?.nationalno}
                                onValueChanged={e => {
                                    this.state.user.nationalno = e.value;
                                    this.forceUpdate();
                                }}
                            >
                                {this.props.adminMode &&
                                    <Validator>
                                        <StringLengthRule message='کد ملی باید ده رقم باشد' min={10} max={10} />
                                        <PatternRule pattern='^[0-9]*$' message='کد ملی فقط شامل ارقام است' />
                                    </Validator>
                                }
                            </TextBox>
                        </SimpleItem>
                        <SimpleItem>
                            <Label>تاریخ تولد</Label>
                            <TarikhPicker //readOnly={!(this.state.user?.id > 0)}
                                value={this.state.user?.bdate_per}
                                date={this.state.bdate}///////////////////////////////////////////
                                min={new Date(new Date().valueOf() - 200 * 365 * 86400000)}
                                max={new Date(new Date().valueOf() - 10 * 365 * 86400000)}
                                onChanged={(a, b) => {
                                    this.state.user.bdate_per = a;
                                    this.state.user.bdate = b?.toDate();
                                    this.forceUpdate();
                                }}
                            />
                        </SimpleItem>
                        {/* <SimpleItem>
                                <Label>شیفت</Label>
                                <CheckBox rtlEnabled={true} value={this.state.user?.isshift}
                                    onValueChanged={e => {
                                        this.state.user.isshift = e.value;
                                        this.forceUpdate();
                                    }} />
                                </SimpleItem> */}
                        <SimpleItem>
                            <Label>ایمیل</Label>
                            <TextBox rtlEnabled={true} //readOnly={!(this.state.user?.id > 0)}
                                value={this.state.user?.email}
                                onValueChanged={e => {
                                    this.state.user.email = e.value;
                                    this.forceUpdate();
                                }}
                            >
                                <Validator>
                                    <EmailRule message='ایمیل به درستی وارد نشده است' />
                                </Validator>
                            </TextBox>
                        </SimpleItem>
                        <SimpleItem>
                            <Label>تلفن</Label>
                            <TextBox rtlEnabled={true} //readOnly={!(this.state.user?.id > 0)}
                                value={this.state.user?.tel}
                                onValueChanged={e => {
                                    this.state.user.tel = e.value;
                                    this.forceUpdate();
                                }}
                            >
                                <Validator>
                                    <PatternRule pattern='^[0-9]+$' message=' شماره تلفن فقط شامل ارقام است' />
                                </Validator>
                            </TextBox>

                        </SimpleItem>
                        <SimpleItem>
                            <Label>موبایل</Label>
                            <TextBox rtlEnabled={true} //readOnly={!(this.state.user?.id > 0)}
                                value={this.state.user?.mobile}
                                onValueChanged={e => {
                                    this.state.user.mobile = e.value;
                                    this.forceUpdate();
                                }}
                            >
                                <Validator>
                                    <StringLengthRule message='شماره موبایل باید یازده رقم باشد' min={11} max={11} />
                                    <PatternRule pattern='^09[0-9]+$'
                                        message=' شماره موبایل با 09 شروع می شود و فقط شامل ارقام است'
                                    />
                                </Validator>
                            </TextBox>
                        </SimpleItem>
                        <SimpleItem>
                            <Label>نشانی</Label>
                            <TextBox rtlEnabled={true} //readOnly={!(this.state.user?.id > 0)}
                                value={this.state.user?.address}
                                onValueChanged={e => {
                                    this.state.user.address = e.value;
                                    this.forceUpdate();
                                }}
                            >
                                {/* <Validator ref={el => this.personCodeValidator = el}>
                                    <CustomRule message='پیام ثابت'
                                        validationCallback={v => {
                                            //console.log(v);
                                            v.rule.message = v.value == 'op' ? null : 'باید دوازده باشد';
                                            return !v.rule.message;
                                        }}
                                    />
                                </Validator> */}

                            </TextBox>
                        </SimpleItem>
                    </GroupItem>
                </Form>
            </ValidationGroup>
        </div>
    );

    renderAccessGroups = () => (
        this.state.user?.id > 0 &&
        <UniGrid key={'asd' + this.state.user.id}
            url={'/api/userAccess/userAccessGroups/' + this.state.user.id}
            readOnly={true} sequenceColumn={false}
            //table='tbl_AccessGroup_User' field='fk_userId' equalTo={this.state.user?.id}
            //hideFields={['fk_userid']}
            height={330} wrapperStyle={{ padding: '20px' }}
        //editingMode='cell'
        //onManualUpdateRow={(id, newData, oldData) => {
        //}}
        //doBeforeInsert={row => row.fk_userid = this.state.user?.id}
        />
    );

    renderPosts = () => (
        <UniGrid key={'awsd' + this.state.user.id} keyExpr='officepostid'
            url={'/api/userAccess/posts/' + this.state.user.id} sequenceColumn={false}
            height={370} wrapperStyle={{ padding: '20px' }}
            readOnly={true} noToolbar={true}
            userKey={this.state.user.currentPostId}
            onuserChanged={focusedPost => {
                this.state.user.currentPostId = focusedPost.officepostid;
                //window.goToRoute('/dashboard');
                //window.loadPermitsInfo();
                this.props.onCurrentPostChanged?.(focusedPost.officepostid);
            }}
        />
    );

    render() {
        if (this.props.dontRender)
            return <></>;

        return (
            <>
                <UniPopup width='400px' commands={[{ text: 'بستن', onClick: 'close' }]} title='آپلود تصویر'
                    visible={this.state.imageUploadingPopupVisible}
                    onClose={e => this.setState({ imageUploadingPopupVisible: false })}
                >
                    <Uploader uploadUrl={`/api/userAccess/saveImageToDb?userId=${this.state.user?.id}&field=${this.state.imageUploadingField}`}
                        accept={this.state.imageUploadingField === 'pic' ? 'image/*' : 'image/png'}
                        maxFileSize={this.state.imageUploadingField === 'pic' ? 250000 : 100000}
                        onUploaded={err => this.setState({
                            uploadError: err,
                            imageKeyCounter: this.state.imageKeyCounter + 1
                        })}
                        onError={err => this.setState({ uploadError: err })}
                    />
                    <div style={{ color: 'red' }}>{this.state.uploadError}</div>
                </UniPopup>

                <div style={this.props.containerStyle}>
                    {/* <ConfirmToSave visible={this.state.showConfirmToSave}
                    onClose={e => {
                        window.saverFunction = null;
                        window.logout();
                    }}
                /> */}

                    {/* <Popup rtlEnabled={true} visible={this.state.rowPopupVisible} width='60rem' height='fit-content'
                    title='ویرایش مشخصات کاربر' showCloseButton={false} shading={true} shadingColor='rgba(0,0,0,0.5)'
                > */}

                    <div style={{ width: this.props.width ?? '60%', display: 'block', margin: 'auto' }} /*className='ka-colorfull-tabpage'*/
                    >
                        <TabPanel rtlEnabled={true} key={'tpnl_userinfo_' + this.state.tabPanelKeyCounter}>
                            <TabPanelItem title='مشخصات فردی'>
                                {this.renderBasicInfo()}
                            </TabPanelItem>

                            {window.user?.isadmin && this.props.adminMode && this.state.user?.id > 0 &&
                                <TabPanelItem title='امضا'>
                                    <div style={{ padding: '3rem' }}>
                                        <UrlImage key={'sign' + this.state.imageKeyCounter}
                                            errorImage='/images/draw.png'
                                            url={this.state.user?.id > 0 && `/api/userAccess/image/${this.state.user?.id}/sign`}
                                            sid={'sign_' + this.state.user?.id + '_' + this.props.sid}
                                            //style={{ height: '200px', display: 'block', margin: 'auto' }}
                                            onClick={e => !(this.state.user?.id > 0)
                                                ? window.notif('ابتدا کد پرسنلی را مشخص کنید')
                                                : this.setState({
                                                    imageUploadingPopupVisible: true,
                                                    imageUploadingField: 'sign'
                                                })}
                                            className='ka-shaddowed'
                                            style={imageStyle}
                                        />
                                    </div>
                                </TabPanelItem>
                            }

                            {this.state.user?.id > 0 && this.props.adminMode &&
                                <TabPanelItem title='گروه های کاربری'>
                                    {this.renderAccessGroups()}
                                </TabPanelItem>
                            }
                            {this.state.user?.id > 0 && this.props.adminMode &&
                                <TabPanelItem title='سمت ها'>
                                    {this.renderPosts()}
                                </TabPanelItem>
                            }
                            {/*
                            {this.state.user?.id > 0 &&
                                <TabPanelItem title='دسترسی به واحدها'>
                                    <UniGrid key={'awe' + this.state.user?.id} table='tbl_User_Device' field='fk_userId' equalTo={this.state.user?.id}
                                        height={360} wrapperStyle={{ padding: '20px' }}
                                    />
                                </TabPanelItem>
                            }
                            {this.state.user?.id > 0 &&
                                <TabPanelItem title='جایگاه های شغلی'>
                                    <UniGrid key={'ajt' + this.state.user?.id} readOnly={true}
                                        noToolbar={true}
                                        height={405} wrapperStyle={{ padding: '20px' }}
                                        rows={this.getUserJobs()}
                                        columns={[
                                            { name: 'id', visible: false },
                                            { name: 'title', caption: 'عنوان' }]
                                        }
                                    />
                                </TabPanelItem>
                            }
                        */}
                        </TabPanel>

                        <br />
                        <Button rtlEnabled={true} text='ذخیره' icon='save' width='10rem' onClick={this.onClickSaveButton} />
                        <Button rtlEnabled={true} text='انصراف' icon='close' width='10rem' onClick={this.props.onCancel} />
                        {this.state.user?.id === window.user?.id && //!this.props.adminMode &&
                            <Button rtlEnabled={true} type='danger' text='خروج' icon='lock' width='10rem'
                                onClick={e => {
                                    window.logout();
                                    // if (!window.saverFunction)
                                    //     window.logout();
                                    // else
                                    //     window.setContentBody(<ConfirmToSave visible={true} onClose={e => window.logout()} />)
                                    // //this.setState({ showConfirmToSave: true });
                                }}
                            />
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default UserInfo;