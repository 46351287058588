import React, { Component } from 'react';

class RiskMatrix extends Component {
    render() {
        return (
            <div style={{ padding: '2rem' }}>
                <img src={`./images/risk_matrix/rm${this.props.assetId % 8}.png`} alt='' width='300px' />
                {/* <table className='risk-matrix'>
                    <tr><td></td>
                    </tr>
                </table> */}
            </div>
        );
    }
}

export default RiskMatrix;