import { Button, FileUploader, TextBox } from 'devextreme-react';
import React, { Component } from 'react';

class Uploader extends Component {
    state = {};

    render() {
        return (
            <FileUploader accept={this.props.accept ?? '*'} uploadMode='instantly' name='ufile'  //"useForm"
                uploadUrl={window.config.API_PREFIX + (this.props.uploadUrl ?? `/api/updown/upload?subPath=${this.props.subPath ?? ''}&nameOnServer=${this.props.nameOnServer ?? '[timehash]'}`)}
                selectButtonText={this.props.selectButtonText ?? 'انتخاب فایل...'} //labelText="آپلود فایل" //uploadButtonText='آپلود' 
                visible={this.props.visible !== false}
                maxFileSize={this.props.maxFileSize} uploadHeaders={{ logonkey: window.user?.logonkey }}
                onUploaded={e => {
                    let resp = JSON.parse(e.request.response);
                    let error = resp?.error;
                    console.log(error);
                    this.props.onUploaded?.(error, resp?.url);
                }}
                onUploadError={e => {
                    console.log(e);
                    this.props.onError?.(e);
                }}
            />
        );
    }
}
//<form id="form" ref={this.formElement} method="post" action="" encType="multipart/form-data"> {/*encType="multipart/form-data">*/}
export default Uploader;