import React, { Component } from 'react';
import { apiGetA, apiGetP, apiPostNoBodyP, apiPostP, updateTree, updateTreeThenReport } from '../../GanjiTools';
import { List, Item as ListItem } from 'devextreme-react/list';
import Inline from '../Inline';
import { Button, CheckBox, DateBox, Form, NumberBox, SelectBox, TextBox, Tooltip } from 'devextreme-react';
import CollapsibleBox from '../utils/CollapsibleBox';

import AspNetData from 'devextreme-aspnet-data-nojquery';
import MinimalButton from '../MinimalButton';
import DataSource from 'devextreme/data/data_source';
import './rbi.scss';

const dsInputsApi = (group, assetId) => AspNetData.createStore({
    key: 'name',
    loadUrl: `${window.config.API_PREFIX}/api/rbi/${group}Inputs?assetId=${assetId}`,

    onBeforeSend(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});

class PropSheets extends Component {
    state = {
        //asset: this.props.asset ?? { id: -1 },
        values: {},
        generalSheetData: {},
        cofSheetData: {},
        dfsSheetData: [],
        sheetsKey: 1,
        generalSheetKey: 1,
        cofSheetKey: 1,
        dfsSheetKey: 1,
    };

    loadGroupP = groups => new Promise((resolve, reject) => {
        let assetId = this.props.asset?.id;

        if (!(assetId > 0)) {
            reject?.('Asset id is not positive');
            return;
        }

        groups ??= 'all';

        apiGetP(`/api/rbi/inputs?assetId=${assetId}&groups=${groups}`)
            .catch(reject)
            .then(res => {
                let { error, warning, cteo, general, cof, df } = res ?? { error: 'No response.' };

                if (error?.length > 0) {
                    reject?.(error);
                    return;
                }

                if (warning?.length > 0)
                    console.log('warning: ', warning);

                resolve({ cteo, general, cof, df });
            })
    });

    postValueP = (name, val) => new Promise((resolve, reject) => {
        if (!(val?.length > 0) || !(name?.length > 0)) {
            reject?.('no name/value!');
            return;
        }

        let assetId = this.props.asset?.id;

        if (!(assetId > 0)) {
            reject?.('Asset id is not positive');
            return;
        }

        apiPostNoBodyP(`/api/rbi/saveInput?assetId=${assetId}&name=${name}&val=${val}`) // { error, propVal, visibilityChanges }
            .catch(reject)
            .then(res => {
                let { error, propVal, visibilityChanges } = res ?? { error: 'No response' };
                if (error?.length > 0) {
                    reject?.(error);
                    return;
                }
                resolve?.({ propVal, visibilityChanges });
            });
    });

    onPropItemValueChanged = (item, value, unitId, sheetName) => {
        item.value = value;
        let valunit = unitId > 0 ? `${value}|${unitId}` : `${value}`;

        this.postValueP(item.name, valunit)
            .catch(window.notif)
            .then(res => {
                let { propVal, visibilityChanges } = res ?? {};
                console.log('result: ', propVal);

                if (visibilityChanges?.length > 0)
                    this.dfsListRef?.instance?.reload();

                this.props.onDataChanged?.({ item, value, unitId });
                //this.dfsListRef?.instance?.getDataSource()?.reload();
                //return;

                // this.loadGroupP('df').catch(window.notif)
                //     .then(res => {
                //         let { error, df } = res ?? { error: 'No response' };
                //         if (error?.length > 0) {
                //             window.notif(error);
                //             return;
                //         }
                //         this.state.generalSheetData.items = df;
                //         this.forceUpdate(() => {
                //             var listds = this.dfsListRef?.instance?.getDataSource();
                //             listds.reload().then();
                //         });
                //     });

                // return;

                // for (let li of this.state.dfsSheetData.items)
                //     for (let lii of li.items)
                //         for (let vc of visibilityChanges)
                //             if (vc.propName.toLowerCase() === lii.name.toLowerCase())
                //                 lii.visible = vc.visible;

                // this.forceUpdate(() => {
                //     var listds = this.dfsListRef?.instance?.getDataSource();
                //     listds.reload().then();
                // });
                // return;

                // console.log('---- dependent props: ', visibilityChanges);

                // var listds = this.dfsListRef?.instance?.getDataSource();
                // let listItems = listds?.items() ?? [];

                // if (!(listItems.length > 0))
                //     return;


                // if (false) {
                //     listds.reload().then(() => {
                //         for (let li of listItems)
                //             for (let lii of li.items)
                //                 for (let vc of visibilityChanges)
                //                     if (vc.propName.toLowerCase() === lii.name.toLowerCase())
                //                         lii.visible = vc.visible;

                //     });
                //     //this.dfsListRef?._checkVisibilityChanged?.();
                //     // this.dfsListRef?.instance?.reload?.()
                //     // this.forceUpdate(() => {
                //     //     //this.dfsListRef?.instance?.reload?.();


                //     //     //listds.reload();
                //     // });



                //     return;
                // }

                // // for (let li of listItems)
                // //     for (let lii of li.items)
                // //         for (let vc of visibilityChanges)
                // //             if (vc.propName.toLowerCase() === lii.name.toLowerCase())
                // //                 lii.visible = vc.visible;

                // listds.reload(() => this.forceUpdate(() => {
                //     for (let li of listItems)
                //         for (let lii of li.items)
                //             for (let vc of visibilityChanges)
                //                 if (vc.propName.toLowerCase() === lii.name.toLowerCase())
                //                     lii.visible = vc.visible;
                // }));//() => this.dfsListRef?.instance?.reload?.());

                // return true;

                // return;

                // for (let pr of this.state.dfsSheetData.items)
                //     for (let pi of pr.items)
                //         for (let vc of visibilityChanges)
                //             if (vc.propName.toLowerCase() === pi.name.toLowerCase())
                //                 pi.visible = vc.visible;


                // if (this.dfsListRef) {
                //     //this.dfsListRef.instance.getDataSource().items()
                //     this.dfsListRef.instance.getDataSource().reload();
                // }

                //this.dfsList?.reload?.()
                //this.forceUpdate();

                //this.setState({ dfsSheetKey: this.state.dfsSheetKey + 1 });

                //this[sheetName + 'Sheet']?.repaint?.();
                //this.setState({ [sheetName + 'SheetKey']: this.state[sheetName + 'SheetKey'] + 1 });
                // this.loadGroupP().catch(window.notif)
                //     .then(res => {
                //         let { df } = res ?? {}; //////////////////////////////
                //         let dfsSheetData = { title: 'Damage Factor Properties', items: df, grouped: true, collapsibleGroups: true }

                // else {
                //     this.setState({
                //         values: {
                //             ...this.state.values,
                //             [item.name]: valunit
                //         }
                //     });
                //     return;
                // }
            });
    }

    renderPropItem = (item, sheetName) => {
        return (
            <PropRow item={item.det} style={{ height: '30px' }} //hidden={item.visible === false}
                value={{ db: item.value, user: item.userEnteredValue }} unitId={item.userEnteredUnitId}
                onValueChanged={(value, unitId) => this.onPropItemValueChanged(item, value, unitId, sheetName)}
            />
        );
    }

    // dsDf = AspNetData.createStore({
    //     key: 'name',
    //     loadUrl: `https://localhost:7018/api/rbi/dfInputs?assetId=${this.props.asset?.id}`,
    //     // insertUrl: `${url}/InsertTask`,
    //     // updateUrl: `${url}/UpdateTask`,
    //     // deleteUrl: `${url}/DeleteTask`,
    //     onBeforeSend(method, ajaxOptions) {
    //         ajaxOptions.xhrFields = { withCredentials: true };
    //     },
    // });

    // dsTest = new DataSource({
    //     store: {
    //         type: 'array',
    //         data: this.state.components,
    //         key: 'compoId',
    //     },
    //     group: 'equipName',
    // });

    // dsDf2 = {
    //     async load(loadOptions) {
    //         const parentIdsParam = loadOptions.parentIds;
    //         const url = new URL(`https://localhost:7018/api/rbi/inputs?assetId=${this.props.asset?.id}&groups=df`);
    //         if (parentIdsParam) {
    //             parentIdsParam.forEach((id) => {
    //                 url.searchParams.append('parentIds', id);
    //             });
    //         }
    //         const result = await fetch(url.toString());
    //         if (result.status === 200) {
    //             return result.json();
    //         }
    //         throw new Error('Data Loading Error');
    //     },
    // };

    renderList = (sheetData, sheetName) => {
        // if (!(sheetData?.items?.length > 0)) //////////////////////////////////////////////////////////
        //     return <></>;       

        if (!sheetData || !sheetData.items)
            return <></>;

        let width = '100%'; // (window.innerWidth * 0.8) + 'px';
        let ds = sheetData.items;//.filter(x => x.visible !== false);
        return (
            <List className='compressed_items' width={width} repaintChangesOnly ref={el => this[sheetName + 'ListRef'] = el}
                //key={sheetData.title.replaceAll(' ', '_') + this.state.sheetsKey + '_' + this.state[sheetName + 'SheetKey'] + '_list'} // width={'100%'} //key={'kkk' + this.state.mmm}
                rtlEnabled={false} dataSource={ds} //ref={el => this.ggg = el}
                height='calc(100vh - 18rem)'//{this.state.mmm ?? 'auto'}//{window.getComputedStyle(document.querySelector('.dx-item-content.dx-accordion-item-body'))?.height ?? '50vh'}
                grouped={sheetData.grouped} collapsibleGroups={sheetData.collapsibleGroups}

                groupRender={i => (
                    <div
                        style={{ height: '100%', width: '100%', textAlign: 'center', margin: '0', fontWeight: '700', background: '#666699', color: 'white', padding: '7px' }}
                    >
                        {i.key}
                    </div>
                )}
                itemRender={item => this.renderPropItem(item, sheetName)}
                activeStateEnabled hoverStateEnabled
            />
        );
    }

    renderSheet = (sheetData, sheetName) => {
        return (
            this.props.useCollapsibleContainer ?
                <CollapsibleBox width='100%' //key={sheetData.title.replaceAll(' ', '_') + this.state.sheetsKey}
                    title={
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', fontWeight: '700', width: '100%' }}>
                            <span style={{ fontWeight: '700' }}>{sheetData.title}</span>
                            <span style={{ margin: '0 1rem' }}>{sheetData.subTitle ?? ''}</span>
                        </div>
                    }
                    rtlEnabled={false}
                    //farItems={<span className='dx-icon-close'></span>}
                    bodyStyle={{ background: 'whitesmoke', ...this.props.style, marginTop: '0', borderRadius: '0 0 1rem 1rem' }}
                    bodyClass='sheet_body'
                >
                    {this.renderList(sheetData, sheetName)}
                </CollapsibleBox>
                :
                this.renderList(sheetData, sheetName)
        );
    }

    logAssetP = () => new Promise((resolve, reject) => {
        apiGetP('/api/rbi/asset?assetId=' + this.props.asset?.id)
            .catch(reject)
            .then(res => {
                let { asset, error } = res ?? { error: 'no response' };
                if (error?.length > 0) {
                    console.log('could not log asset: ', error);
                    reject?.(error);
                    return;
                }
                console.log('========================================');
                console.log(asset);
                resolve?.(asset);
            })
    });

    componentDidMount() {
        if (this.props.asset?.id > 0)
            this.loadGroupP('general').catch(window.notif)
                .then(res => {
                    //let { cteo, general, cof, df } = res ?? {};
                    //let generalSheetData = { title: 'General Properties', items: general, grouped: true, collapsibleGroups: true };
                    //let cofSheetData = { title: 'Consequene Properties', items: cof, grouped: true, collapsibleGroups: true };
                    //let dfsSheetData = { title: 'Damage Factor Properties', items: df, grouped: true, collapsibleGroups: true },
                    let { cteo } = res ?? {};
                    let generalSheetData = { title: 'General Properties', items: dsInputsApi('general', this.props.asset?.id), grouped: true, collapsibleGroups: true };
                    let cofSheetData = { title: 'Consequene Properties', items: dsInputsApi('cof', this.props.asset?.id), grouped: true, collapsibleGroups: true };
                    let dfsSheetData = { title: 'Damage Factor Properties', items: dsInputsApi('df', this.props.asset?.id), grouped: true, collapsibleGroups: true };

                    this.setState({
                        componentType: cteo?.[0].items?.find(x => x.name === 'CompoType')?.displayValue,
                        equipmentType: cteo?.[0].items?.find(x => x.name === 'EquipType')?.displayValue,
                        isAtmosphericTank: cteo?.[0].items?.find(x => x.name === 'IsAtmosphericTank')?.value,
                        generalSheetData,
                        cofSheetData,
                        dfsSheetData,
                    });
                });

        //     .then(basicInfo => {
        //         this.loadDataP().catch(window.notif)
        //             .then(res => {
        //                 if (!(res?.length > 0)) {
        //                     if (basicInfo)
        //                         this.setState({ ...basicInfo });
        //                     return;
        //                 }

        //                 let [general, cofDic, dfDic] = res;
        //                 let generalSheetData = { title: 'General Properties', items: general };
        //                 let cofSheetData = { title: 'Consequene Properties', items: cofDic, grouped: true, collapsibleGroups: true };
        //                 let dfsSheetData = dfDic?.map(x => ({ id: x.id, title: x.key, items: x.list })) ?? [];

        //                 console.log('basicInfo= ', basicInfo);
        //                 console.log('generalSheetData= ', generalSheetData);
        //                 console.log('cofSheetData= ', cofSheetData);
        //                 console.log('dfsSheetData= ', dfsSheetData);
        //                 //console.log(basicInfo, generalSheetData, cofSheetData, dfsSheetData);
        //                 this.setState({ ...basicInfo, generalSheetData, cofSheetData, dfsSheetData });
        //             });
        //     });
    }

    render() {
        return (!(this.props.asset?.id > 0) ? <p style={{ padding: '3rem' }}>Select an Asset from the Left Tree.</p> :
            <div style={{ padding: '1rem' }} //key={'cnt_' + this.state.sheetsKey}
            >
                {this.props.showAssetInfo &&
                    <p style={{ padding: '5px', fontSize: '0.8rem', color: '#aaaaaa' }}>
                        Asset Id= {this.props.asset?.id} , Equipment={this.state.equipmentType} , Component={this.props.asset?.compoName} , AtmosphericTank={this.props.asset?.isAtmosphericTank ? 'Yes' : 'No'}
                    </p>
                }
                {this.props.showGeneral && this.renderSheet(this.state.generalSheetData ?? {}, 'general')}
                {this.props.showCof && this.renderSheet(this.state.cofSheetData ?? {}, 'cof')}
                {this.props.showAllDfs && this.renderSheet(this.state.dfsSheetData ?? {}, 'dfs')}
                {/* <br /> */}
                {/* <Button text='Caclualte' onClick={() => this.onCalculate()} type='success' /> */}
            </div>
        );
    }
}

//--------------------------------------------------- PropRow ------------------------------------------------------

export class PropRow extends Component {
    id = 'sheetRowContainer|' + this.props.item.displayName; //: generateUUID());

    state = {
        userValue: this.props.value?.user,
        dbValue: this.props.value?.db,
        unitId: this.props.unitId ?? 1,
    };

    componentDidMount() {
        this.setState({ containerElem: document.getElementById(this.id) });
    }

    render() {
        let i = this.props.item ?? {};

        return (
            <Inline style={this.props.style} >
                <div className='prop_label' style={{ width: '50%', color: this.props.hidden ? 'red' : undefined }}
                    id={this.id}
                >
                    {i.displayName} {i.isLeader ? '#' : ''} {i.hint?.length > 0 ? ' (i)' : ''} {i.isOptional ? ' (optional)' : ''}
                </div>
                {i.hint?.length > 0 &&
                    <Tooltip target={this.state.containerElem} hideEvent='mouseleave'
                        showEvent={{ name: 'mouseenter', delay: 1000 }}
                    >
                        {i.hint}
                    </Tooltip>
                }
                {i.lookup?.length > 0
                    ?
                    <SelectBox dataSource={i.lookup} valueExpr='id' displayExpr='summary' width='50%'
                        value={this.state.dbValue}
                        onValueChanged={e => this.setState({ dbValue: e.value },
                            () => this.props.onValueChanged?.(e.value, 0))
                        }
                    />
                    :
                    i.dataType?.toLowerCase() === 'boolean' ?
                        <div style={{ width: '50%', textAlign: 'center' }}>
                            <CheckBox value={['1', 'true', 'yes'].includes((this.state.userValue?.toString() ?? '').toLowerCase())}
                                onValueChanged={e => this.setState({ userValue: e.value, unitId: 0 },
                                    () => this.props.onValueChanged?.(e.value, 0))
                                }
                            />
                        </div>
                        :
                        i.dataType?.toLowerCase() === 'datetime' ?
                            <DateBox width='50%'
                                displayFormat='yyyy/MM/dd'
                                value={this.state.userValue ?? new Date()}
                                onValueChanged={e => this.setState({ userValue: e.value, unitId: 0 },
                                    () => this.props.onValueChanged?.(e.value, 0))}
                            />
                            :
                            i.unitLookup?.length > 0
                                ?
                                <Inline width='50%'>
                                    <NumberBox width='100%' value={this.state.userValue}
                                        onValueChanged={e => this.setState({ userValue: e.value },
                                            () => this.props.onValueChanged?.(e.value, this.state.unitId)
                                        )}
                                    />
                                    <SelectBox dataSource={i.unitLookup} valueExpr='id' displayExpr='summary' width='100%'
                                        value={this.state.unitId}
                                        onValueChanged={e => this.setState({ unitId: e.value },
                                            () => this.props.onValueChanged?.(this.state.userValue, e.value))
                                        }
                                    />
                                </Inline>
                                :
                                <NumberBox width='50%' min={i.min} max={i.max}
                                    value={this.state.userValue}
                                    onValueChanged={e => this.setState({ userValue: e.value },
                                        () => this.props.onValueChanged?.(e.value, 0))}
                                />
                }
            </Inline>
        );
    }
}

export default PropSheets;