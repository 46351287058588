import React, { Component } from 'react';
import './VariableDisplay.scss';
import Space from '../Space';

class VariableDisplay extends Component {
    render() {
        let name = (this.props.name ?? '').replace('ᐨ', '-').replace('ᛧ', ',');
        let tops = name.split('ᐞ'), bots = name.split('_');
        let superScript = tops.length < 2 ? '' : tops[1].split('_')[0];
        let subScript = bots.length < 2 ? '' : bots[1].split('ᐞ')[0];
        let body = tops[0].split('_')[0];

        return (
            <div style={this.props.style}>
                <span className='nobr' style={{ fontSize: this.props.fontSize ?? '14px' }}>
                    {body}
                    <Space width='2px' />
                    <span class='supsub'>
                        {superScript}
                        <br />
                        {subScript}
                    </span>
                </span>

            </div>
        );
    }
}

export default VariableDisplay;