import React, { Component } from 'react';
import { List, Switch } from 'devextreme-react';
import Inline from '../components/Inline';

class SelectBySwitches extends Component {
    state = {
        selectedKeys: this.props.selectedKeys ?? [] // [1]
    }

    renderItem = i => {
        let on = this.state.selectedKeys.includes(i.id);

        return (
            <Inline spacing='5px' style={{ padding: '0' }}>
                <Switch value={on}
                    onValueChanged={e => {
                        let selectedKeys = e.value ? [...this.state.selectedKeys, i.id] : this.state.selectedKeys.filter(x => x !== i.id);
                        this.props.onSelectedKeysChanged?.(selectedKeys);
                        this.setState({ selectedKeys });
                    }}
                />
                <span style={{ fontWeight: on ? '800' : '500', padding: '0' }}
                    onClick={() => {
                        let selectedKeys = on ? this.state.selectedKeys.filter(x => x !== i.id) : [...this.state.selectedKeys, i.id];
                        this.props.onSelectedKeysChanged?.(selectedKeys);
                        this.setState({ selectedKeys });
                    }}
                >
                    {i.summary}
                </span>

            </Inline>
        );
    }

    render() {
        return (
            <List dataSource={this.props.dataSource ?? this.props.items}
                key={'switches_' + this.state.selectedKeys.length}
                focusStateEnabled={false} hoverStateEnabled
                itemRender={this.renderItem}
            />
        );
    }
}

export default SelectBySwitches;