import React, { Component } from 'react';
import { apiPostP } from '../../GanjiTools';
import { Form, Button, TextArea, TextBox } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import { Label, SimpleItem } from 'devextreme-react/form';
import MinimalButton from '../MinimalButton';
import Inline from '../Inline';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import VariableDisplay from './VariableDisplay';
import Space from '../Space';

class VerifyingGrid extends Component {

    state = {
        commentPopVisible: false,
        grdKey: 1,
        ds: this.props.ds ?? [],
    };

    renderCheckingColumn = commentingRow => {
        return (
            <Inline spacing='5px' style={{ justifyContent: 'center' }}>
                <MinimalButton icon='check' onClick={() => this.onVerified(commentingRow)}
                    iconStyle={{ color: commentingRow.verified === true ? 'green' : 'lightgray' }}
                    hint='Value is correct'
                />
                <MinimalButton icon='clear' iconStyle={{ color: commentingRow.verified === false ? 'red' : 'lightgray' }}
                    hint='Value is wrong'
                    onClick={() => {
                        this.setState({ commentingRow: { ...commentingRow, verified: false }, commentPopVisible: true });
                    }}
                />
            </Inline>
        )
    };

    onVerified = commentingRow => {
        if (commentingRow?.varName?.length > 0 && this.props.assetId > 0)
            apiPostP(`/api/rbi/verify?assetId=${this.props.assetId}&varName=${commentingRow.varName}&ownerClass=${commentingRow.ownerClass}&verified=true`, {})
                .catch(window.notif)
                .then(res => {
                    let { error, catLogs } = res ?? { error: 'No response.' };
                    if (error?.length > 0)
                        window.notif(error);
                    else
                        this.setState({ ds: catLogs[this.props.cat] });
                });
    }

    onNotVerifyPopupOk = () => {
        if (this.props.assetId > 0 && this.state.commentingRow?.varName?.length > 0)
            apiPostP(`/api/rbi/verify?assetId=${this.props.assetId}&varName=${this.state.commentingRow.varName}&ownerClass=${this.state.commentingRow.ownerClass}&verified=false`,
                { note: this.state.commentingRow?.verifierNote, correctValue: this.state.commentingRow?.verifierCorrectValue })
                .catch(window.notif)
                .then(res => {
                    let { error, verification, catLogs } = res ?? { error: 'No response.' };
                    if (error?.length > 0)
                        window.notif(error);
                    else
                        this.state.ds = catLogs[this.props.cat];

                }).finally(() =>
                    this.setState({ commentPopVisible: false, })
                );
        //else
        //    this.setState({ commentPopVisible: false });
    }

    render() {

        return (
            <>
                <DataGrid dataSource={this.state.ds} wordWrapEnabled
                    height='30rem' paging={false} width='calc(100% - 10px)' //hoverStateEnabled
                    columnAutoWidth rowAlternationEnabled sorting={false} //scrolling='standard'
                >
                    <Column caption='Parameter' allowGrouping={false}
                        cellRender={c => <VariableDisplay name={c.data?.varName} fontSize='20px' style={{ paddingLeft: '0.5rem' }} />}
                    />

                    <Column caption='Value' allowGrouping={false} dataField='value'
                    />

                    <Column caption='Verify' allowGrouping={false} alignment='center'
                        cellRender={r => this.renderCheckingColumn(r?.data)}
                    />

                    {this.state.ds?.length > 0 && this.state.ds[0] &&
                        Object.keys(this.state.ds[0]).filter(x => x !== 'varName' && x !== 'value' && x !== 'id' && x !== 'ownerClass' && x !== 'inspectorComment'
                            && x !== 'verified' && x !== 'verifierCorrectValue' && x !== 'assetId')
                            .map(x => <Column dataField={x} />)
                    }

                </DataGrid>

                <Popup title='Not Verified?' visible={this.state.commentPopVisible} onHidden={e => this.setState({ commentPopVisible: false })}
                    width='50rem' height='auto'
                >
                    <Form colCount={60}>
                        <SimpleItem colSpan={60}>
                            <Inline>
                                <span>Do you think this value is wrong? </span>
                                <Space width='1rem' />
                                <VariableDisplay name={this.state.commentingRow?.varName} fontSize='14px' />
                                <span>= {this.state.commentingRow?.value}</span>
                            </Inline>
                        </SimpleItem>

                        <SimpleItem colSpan={60}>
                            <Label>Your correct value:</Label>
                            <TextBox value={this.state.commentingRow?.verifierCorrectValue}
                                onValueChanged={e => this.setState({
                                    commentingRow: {
                                        ...this.state.commentingRow,
                                        verifierCorrectValue: e.value
                                    }
                                })}
                            />
                        </SimpleItem>

                        <SimpleItem colSpan={60}>
                            <Label>Your comment:</Label>
                            <TextArea height={'5rem'} value={this.state.commentingRow?.verifierNote}
                                onValueChanged={e => this.setState({
                                    commentingRow: {
                                        ...this.state.commentingRow,
                                        verifierNote: e.value
                                    }
                                })}
                            />
                        </SimpleItem>
                    </Form>
                    <br />
                    <Button text='OK' width='10rem' onClick={() => this.onNotVerifyPopupOk()} />
                </Popup>
            </>
        );
    }
}

export default VerifyingGrid;