import React, { Component } from 'react';
import { UniGrid } from './UniGrid';
import MasterLayout from './layout/MasterLayout';
import Inline from './Inline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommandManager from './CommandManager';
import { apiGet, apiPost } from '../GanjiTools';
import { Button, TextBox } from 'devextreme-react';
import Header from './layout/Header';
import SideDivider from './layout/SideDivider';
// import { faBullseye } from '@fortawesome/free-solid-svg-icons';

export default class UniPage extends Component {
    constructor(props) {
        super(props);
        this.saveStates = this.saveStates.bind(this);
    }

    state = {
        menuItem: this.props.menuItem ?? {},
        grdKey: 1,
        newPageTitle: this.props.menuItem?.pagetitle ?? this.props.menuItem?.desp ?? '',
        newUrl: '',
        showAssigning: false,
    }

    componentWillUnmount() {
        MasterLayout.onResizeEvent = MasterLayout.onResizeEvent.filter(x => x.key === 'uniPage');
        console.log('>> UniPage is unmounted.');
    }

    componentDidUpdate() {
    }

    saveStates() {
        //console.log('>> Save states of UniPage.');
        this.grid?.saveStates?.();
    }

    renderComponent = () => {
        let item = this.state.menuItem;
        let slc = item?.url?.split('|');
        let url = slc?.length > 0 ? slc[0] : '';

        let props = JSON.parse(slc?.find(x => x.startsWith('props:'))?.substring(6) ?? '{}');

        if (url?.startsWith('table:'))
            return ({
                error: '', component:
                    <UniGrid sid={`auto_table_${item?.id}`} saveState table={url.substring(6).split('|')[0]}
                        height={MasterLayout.bodyHeight - 35} width={`calc(${SideDivider.bodyWidth}px - 2rem)`}
                        noMetaGrid={false} favButton topMenuItem={item} ref={el => this.grid = el}
                        {...props} {...this.props.additionalProps}
                    />
            });

        if (url?.startsWith('url:'))
            return ({
                error: '', component:
                    <UniGrid sid={`auto_url_${item?.id}`} /*saveState*/ url={url.substring(4).split('|')[0]}
                        noMetaGrid={false} favButton topMenuItem={item}
                        height={MasterLayout.bodyHeight - 35} width={`calc(${SideDivider.bodyWidth}px - 2rem)`}
                        ref={el => this.grid = el}
                        {...props} {...this.props.additionalProps}
                    />
            });

        if (url?.startsWith('component:'))
            url = url.substring('component:'.length);

        let compf = CommandManager.routesDic.find(x => x.key.toLowerCase() === url?.toLowerCase()?.trim())?.component;

        if (compf) {
            let hasMenu = this.state.menuItem?.id > 0;

            if (typeof (compf) === 'function') {
                if (!hasMenu)
                    this.state.menuItem.pagetitle = `<${url}>`;

                return ({
                    error: '',
                    component: compf({
                        ...props,
                        ...this.props.additionalProps,
                        topMenuItem: this.state.menuItem,
                        favButton: hasMenu
                    })
                });
            }

            return ({ error: '', component: compf }); // ...props ...additionalProps ???
        }

        return ({
            error: `Page not found! (URL= ${url}) , (menuID= ${item?.id})`, component:
                <div style={{ padding: '3rem', fontSize: '1.2rem', color: 'red', direction: 'ltr' }}
                >
                    <Inline spacing='1rem' style={{ margin: '1rem' }}>
                        <FontAwesomeIcon icon='warning' fontSize='2rem' beatFade />
                        <div>Error: command or page '{url}' (id={item?.id}) has not found!</div>
                    </Inline>
                    {item?.isfavorite &&
                        <Button icon='trash' text='حذف میانبر' onClick={() => Header.setFavoriteMenu(item, 'delete')}
                            rtlEnabled
                        />
                    }
                    {/* {item?.id > 0 && //this.state.showAssigning &&
                        <PageAssigner menuItem={item} onSaved={this.forceUpdate} />
                    } */}

                    {/* {item?.id > 0 &&
                        <Inline spacing='0.5rem'>
                            <TextBox placeholder='Enter new URL' width='20rem'
                                value={this.state.newUrl}
                                onValueChanged={e => this.setState({ newUrl: e.value })} />
                            <TextBox placeholder='Enter page title' width='20rem'
                                value={this.state.newPageTitle}
                                onValueChanged={e => this.setState({ newPageTitle: e.value })} />
                            <Button icon='save' text='Save'
                                onClick={() => apiPost('/api/userAccess/saveTopMenuItem',
                                    { id: item.id, url: this.state.newUrl, pageTitle: this.state.newPageTitle },
                                    res => {
                                        if (res.error?.length > 0)
                                            window.notif(res.error);
                                        else
                                            this.forceUpdate();
                                    },
                                    err => window.notif("Error: ", err)
                                )}
                            />
                        </Inline>
                    } */}
                </div>
        });
    }

    // for non-unigrid pages
    static renderTitleBox = ({ owner, favButton, onFavChanged, style, onAssignerSaved }) => {

        let topMenuItem = owner?.state?.topMenuItem;

        return (!topMenuItem ? <></> :
            <>
                <Inline spacing='0.5rem' style={style}
                >
                    {favButton !== false &&
                        <Button width={35} //key={'favbtn' + this.state.favKey + '_' + this.state.topMenuItem.isfavorite} 
                            hint={topMenuItem.isfavorite ? 'حذف از برگزیده ها' : 'افزودن به برگزیده ها'}
                            onClick={() => Header.setFavoriteMenu(topMenuItem,
                                topMenuItem.isfavorite ? 'delete' : 'add', () => onFavChanged?.(topMenuItem.isfavorite)
                            )}
                        >
                            <FontAwesomeIcon //key={'favicon' + this.state.favKey + '_' + this.state.topMenuItem.isfavorite}
                                icon={topMenuItem.isfavorite ? 'bookmark' : 'star'} beatFade={!topMenuItem.isfavorite}
                            />
                        </Button>
                    }

                    {topMenuItem?.id > 0 &&
                        <Button>
                            <FontAwesomeIcon icon='ellipsis' hint='انتساب کمپوننت/آدرس به منو'
                                onClick={() => owner?.setState({ showAssigning: !owner.state?.showAssigning })} />
                        </Button>
                    }

                    {topMenuItem?.id > 0 &&
                        <img src={'/images/menu/' + topMenuItem.id + '.png'} width={27} alt='' />
                    }
                    {topMenuItem?.pagetitle &&
                        < div style={{ fontWeight: '700', fontSize: '1.3rem' }}>{topMenuItem?.pagetitle}</div>
                    }

                </Inline>
                {owner?.state?.showAssigning &&
                    <>
                        <br />
                        <PageAssigner menuItem={topMenuItem} textBoxWidth='50rem'
                            onClose={owner.setState({ showAssigning: false })}
                            onSaved={onAssignerSaved}
                        />
                    </>
                }
            </>
        );
    }

    componentDidMount() {
        //console.log('>> UniGrid is mounted.');
        MasterLayout.onResizeEvent.push({ key: 'uniPage', caller: this });

        if (this.state.menuItem?.url?.length > 0 && !(this.state.menuItem.id > 0) && !this.state.menuItem.url.startsWith('component:'))
            apiGet(`/api/userAccess/topMenuItem/${this.state.menuItem.url}`,
                menuItem => {
                    if (menuItem)
                        this.setState({ menuItem, grdKey: this.state.grdKey + 1 })
                }
            );
    }

    render() {
        let { component, error } = this.renderComponent();
        let item = this.state.menuItem;
        console.log('Unipage rendering: ', this.state.menuItem);

        return (
            <div style={{ margin: '0 1rem' }} key={`unipgridu${this.state.grdKey}`}>
                {item?.id > 0 && (this.state.showAssigning || error?.length > 0) &&
                    <PageAssigner menuItem={item} onSaved={this.forceUpdate} />
                }
                {component}
            </div>
        );
    }
}

export class ComponentPageTitle extends Component {
    state = {
        showAssigning: false,
    };

    render() {
        let topMenuItem = this.props.menuItem;

        return (!topMenuItem ? <></> :
            <>
                <Inline spacing='0.5rem' style={this.props.style}
                >
                    {this.props.favButton !== false &&
                        <Button width={35} //key={'favbtn' + this.state.favKey + '_' + this.state.topMenuItem.isfavorite} 
                            hint={topMenuItem.isfavorite ? 'حذف از برگزیده ها' : 'افزودن به برگزیده ها'}
                            onClick={() => Header.setFavoriteMenu(topMenuItem,
                                topMenuItem.isfavorite ? 'delete' : 'add', () => this.props.onFavChanged?.(topMenuItem.isfavorite)
                            )}
                        >
                            <FontAwesomeIcon //key={'favicon' + this.state.favKey + '_' + this.state.topMenuItem.isfavorite}
                                icon={topMenuItem.isfavorite ? 'bookmark' : 'star'} beatFade={!topMenuItem.isfavorite}
                            />
                        </Button>
                    }

                    {topMenuItem?.id > 0 &&
                        <Button>
                            <FontAwesomeIcon icon='ellipsis' hint='انتساب کمپوننت/آدرس به منو'
                                onClick={() => this.setState({ showAssigning: !this.state.showAssigning })} />
                        </Button>
                    }

                    {topMenuItem?.id > 0 &&
                        <img src={'/images/menu/' + topMenuItem.id + '.png'} width={27} alt='' />
                    }

                    {topMenuItem?.pagetitle &&
                        < div style={{ fontWeight: '700', fontSize: '1.3rem' }}>{topMenuItem?.pagetitle}</div>
                    }

                </Inline>
                {this.state.showAssigning &&
                    <>
                        <br />
                        <PageAssigner menuItem={topMenuItem} textBoxWidth='50rem'
                            onClose={() => this.setState({ showAssigning: false })}
                            onSaved={this.props.onAssignerSaved}
                        />
                    </>
                }
            </>
        );
    }
}

export class PageAssigner extends Component {

    state = {
        newUrl: this.props.menuItem?.url ?? '',
        newPageTitle: this.props.menuItem?.pagetitle ?? '',
        menuItem: this.props.menuItem ?? { id: -1 },
    };

    render() {
        //console.log('+++++++++++ ', this.state.menuItem);

        return (
            this.state.menuItem.id <= 0 ? <></> :
                <div>
                    <Inline spacing='10px' //style={{ marginBottom: '1rem' }}
                    >
                        <strong>{this.state.menuItem.text}</strong>
                        {/* <FontAwesomeIcon icon='rectangle-xmark' onClick={() => this.props.onClose?.()} /> */}
                        <strong>Menu ID = {this.state.menuItem.id}</strong>
                    </Inline>
                    <br />
                    <TextBox placeholder='Enter new URL' width={this.props.textBoxWidth ?? '25rem'} rtlEnabled={false}
                        value={this.state.newUrl} hint='آدرس'
                        onValueChanged={e => this.setState({ newUrl: e.value })}
                    />
                    <br />
                    <TextBox placeholder='Enter page title' width={this.props.textBoxWidth ?? '25rem'}
                        value={this.state.newPageTitle} hint='عنوان صفحه'
                        onValueChanged={e => this.setState({ newPageTitle: e.value })}
                    />
                    <br />
                    <Inline spacing='10px'>
                        <Button icon='save' text='ذخیره' rtlEnabled
                            onClick={() => apiPost('/api/userAccess/saveTopMenuItem',
                                { id: this.state.menuItem.id, url: this.state.newUrl, pageTitle: this.state.newPageTitle },
                                res => {
                                    if (res.error?.length > 0)
                                        window.notif(res.error);
                                    else {
                                        this.forceUpdate();
                                        this.props.onSaved?.();
                                    }
                                },
                                err => err && window.notif("Error: ", err)
                            )}
                        />
                        <Button icon='close' text='بستن' rtlEnabled
                            onClick={() => this.props.onClose?.()}
                        />
                    </Inline>
                </div>
        );
    }
}
