/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import Inline from '../Inline';
import MinimalButton from '../MinimalButton';
import { apiPostNoBody } from '../../GanjiTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Space from '../Space';
import { isMobile } from 'react-device-detect';

class Header extends Component {
    state = {
        shortcuts: [], ////////////////////////////
    };

    _addShortcut = (menuItem, add) => {
        if (add) {
            if (menuItem?.id > 0 && !this.state.shortcuts.find(x => x.id === menuItem.id)) {
                menuItem.isfavorite = true;
                this.state.shortcuts.push(menuItem);
                this.forceUpdate();
                return;
            }
        }
        else {  // remove
            menuItem.isfavorite = false;
            this.state.shortcuts = this.state.shortcuts.filter(x => x.id !== menuItem?.id);
            this.forceUpdate();
        }
    }

    static removeShortcut = menuItem => {
        Header.current?._addShortcut(menuItem, false);
    }

    static addShortcut = menuItem => {
        Header.current?._addShortcut(menuItem, true);
    }

    onDeleteShortcut = mi => {
        mi.id > 0 &&
            apiPostNoBody('/api/userAccess/favoriteMenu/delete/' + mi.id,
                res => {
                    if (res.error?.length > 0)
                        window.notif(res.error);
                    else {
                        this.state.shortcuts = this.state.shortcuts.filter(x => x.id !== mi.id);
                        this.forceUpdate();
                    }
                },
                err => window.notif('error: ', err)
            );
    }

    static setFavoriteMenu = (menuItem, cmd, whenOk, whenError) => {
        let id = menuItem?.id;
        cmd ??= 'add';
        if (id > 0)
            apiPostNoBody(`/api/userAccess/favoriteMenu/${cmd}/${id}`,
                res => {
                    if (res.error?.length > 0) {
                        window.notif(res.error);
                        whenError?.(res.error);
                    }

                    else if (cmd === 'add')
                        Header.addShortcut(menuItem);
                    else if (cmd === 'delete' || cmd === 'remove')
                        Header.removeShortcut(menuItem);

                    if (res.warning?.length > 0)
                        console.log(res.warning);

                    whenOk?.();
                },
                err => {
                    window.notif("Error: ", err);
                    whenError?.(err);
                }
            );
    }

    componentDidMount() {
        Header.current = this;
    }

    render() {
        return (
            <>
                {this.props.nearItems}
                <Inline spacing='2px' height='100%' width='100%' // width='calc(100vw - 60px)'
                    style={{ overflowX: 'hidden' }}
                    farItems={
                        <Inline spacing='0.5rem' height='100%'>
                            {isMobile || window.qrToFill > 0 ?
                                <MinimalButton rtlEnabled={true} height='100%' width='3rem' stylingMode='text'
                                    onClick={() => window.logout()}
                                    style={{ padding: '0 1rem' }}
                                >
                                    {`${window.user?.fullname ?? window.user?.username}`}
                                    <Space width='0.6rem' />
                                    <FontAwesomeIcon icon='power-off' beatFade fontSize='1.1rem' color='red' />
                                </MinimalButton>
                                :
                                <MinimalButton rtlEnabled={true} height='100%' width='3rem' stylingMode='text'
                                    onClick={this.props.onUserIconClick}
                                >
                                    {`${window.user?.fullname ?? window.user?.username}`}
                                    <Space width='0.6rem' />
                                    <FontAwesomeIcon icon='circle-user' beatFade fontSize='1.3rem' />
                                </MinimalButton>
                            }
                            <Space width='5px' />
                        </Inline>
                    }
                >
                    {/* {this.state.shortcuts?.map(x => <MenuShortcut menuItem={x} onDelete={this.onDeleteShortcut} />)} */}
                    <Space width='2rem' />
                    {/* <p>نظرسنجی</p> */}
                </Inline>
            </>
        );
    }
}

export default Header;