/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
//import 'devextreme/dist/css/dx.light.css';
import ContextMenu from 'devextreme-react/context-menu';
import Button from 'devextreme-react/button';
import DataGrid, {
    Scrolling, Editing, Form, Column, Paging, SearchPanel, HeaderFilter, FilterRow, Selection, Export,
    FilterBuilderPopup, Popup, Toolbar as GToolbar, Item as GToolbarItem, Sorting, Grouping,
    StateStoring as GStateStoring, FilterPanel as GFilterPanel
} from 'devextreme-react/data-grid';
import TreeList, {
    Toolbar as TToolbar,
    Item as TToolbarItem,
    StateStoring as TStateStoring, FilterPanel as TFilterPanel,
} from 'devextreme-react/tree-list';
import { Item as Form_Item, SimpleItem } from 'devextreme-react/form';
//import { Item as Toolbar_Item } from 'devextreme-react/toolbar';
import { apiPost, apiPut, apiDelete, toLowerKeys, apiGetA } from '../GanjiTools';
import { ScrollBar } from 'devextreme-react/chart';
//import { DateBox, LoadIndicator, TextBox } from 'devextreme-react';
import TarikhPicker from './TarikhPicker';
import TarikhTimePicker from './TarikhTimePicker';
import UniMetaGrid from './UniMetaGrid';
import { invalidUser_component } from '../commonTools';
import TimePickerHHMM from './../components/TimePicker';
import Spinner from './utils/Spinner';
import localforage from 'localforage';
import Inline from './Inline';
import Header from './layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Lookup, SelectBox } from 'devextreme-react';
import { PageAssigner } from './UniPage';

function defLookupDataSource(x) {
    return (x && Array.isArray(x) && x.length > 0 &&
    {
        dataSource: x,
        keyExpr: 'id',
        valueExpr: 'id',
        displayExpr: 'summary',
    });
}

export class UniGrid extends React.Component {
    constructor(props) {
        super(props);

        this.selectedKeys = props.selectedKeys;

        this.state = {
            gridKey: 1,
            placeholder: 'جستجو...',
            rtlEnabled: !this.props.ltr,
            contextMenuItems: props.contextMenuItems,
            columns: props.columns, // toLowerKeys(props.columns),
            rows: props.rows ?? props.dataSource,
            loading: true,
            groupBy: this.props.groupBy,
            parentFieldName: this.props.parentFieldName,
            focusedRow: undefined,
            showUniSettings: this.props.showUniSettings,
            topMenuItem: this.props.topMenuItem,
            favKey: 1,
            forceUseGrid: this.props.forceUseGrid === true,
            outerDivKey: 1,
            dontSaveStates: this.props.saveState === false,
        };

        this.saveStates = this.saveStates.bind(this);
        this.loadStates = this.loadStates.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadMetaData = this.loadMetaData.bind(this);
        this.refineColumns = this.refineColumns.bind(this);
        this.onRowUpdating = this.onRowUpdating.bind(this);
        this.onRowInserting = this.onRowInserting.bind(this);
        this.onRowRemoving = this.onRowRemoving.bind(this);
        this.autoUpdateOrInsertToTable = this.autoUpdateOrInsertToTable.bind(this);
        this.sid = props.sid ?? props.key ?? props.title ?? props.id ?? ('t' + Number(new Date()));

        //this.loadStates().then();
        //this.onExporting = this.onExporting.bind(this);
    }

    async loadMetaData() {
        let tblsid = this.props.table?.length > 0 ? this.props.table : this.props.sid?.length > 0 ? 'sid:' + this.props.sid : '';
        if (!(tblsid.length > 0)) {
            return; // throw 'error: no table name!';
        }

        let metaRows = await apiGetA('/api/uni/rows/tbl_UniGridSetting/TableName/' + tblsid);
        this.setState({ metaRows, groupBy: this.props.groupBy ?? metaRows.find(x => x.isGroup)?.columnName });
    }

    async refineColumns(columns) {
        let changed = false;
        if (!(columns?.length > 0))
            return changed;

        if (this.props.modifyFields?.length > 0)
            for (let mf of this.props.modifyFields) {
                let mfn = mf.name.toLowerCase()
                let pf = columns.find(x => x.name.toLowerCase() === mfn);
                if (pf) {
                    for (let key of Object.keys(mf))
                        pf[key] = mf[key];
                    changed = true;
                }
            }

        if (this.props.showFields?.length > 0)
            for (let col of columns) {
                col.visible = this.props.showFields.find(x => x.toLowerCase() === col.name?.toLowerCase()) ? true : false;
                if (!col.visible)
                    changed = true;
            }

        if (this.props.hideFields?.length > 0)
            for (let mf of this.props.hideFields) {
                let pf = columns.find(x => x.name.toLowerCase() === mf.toLowerCase());
                if (pf) {
                    pf.visible = false;
                    changed = true;
                }
            }

        if (this.props.unhideFields?.length > 0)
            for (let mf of this.props.unhideFields) {
                let pf = columns.find(x => x.name.toLowerCase() === mf.toLowerCase());
                if (pf) {
                    pf.visible = true;
                    changed = true;
                }
            }

        if (columns.find(x => x.order)) {
            columns = columns.sort((a, b) => (a.order ?? 9999) - (b.order ?? 9999));
            changed = true;
        }

        //console.log('>> ofields: ', ofields);

        for (let x of columns) { // converting lookup tables to array
            if (x.lookup?.length > 0 && typeof (x.Lookup) === 'string') {
                x.lookup = JSON.parse(x.lookup);
                changed = true;
            }
            else if (x.lookup?.table) {
                let d = await apiGetA(`/api/uni/lookup3/${x.lookup.table}/<>/<>${(x.lookup?.field ? ('/' + x.lookup.field + '/' + (x.lookup.equalTo ?? 'null')) : '')}`);
                x.lookup = x.lookup?.refine ? d.filter(x.lookup.refine) : d;
                x.format = 1;
                changed = true;
            }
        }

        return changed;
    }

    async loadData(whenDone) {
        let sk = {};
        if (this.props.selectedKeys?.length > 0) {
            this.props.selectedKeys.forEach(x => sk[x] = { checked: true });
            this.state.selectedKeys = sk; // } /*, () => console.log('selected ids: ', this.props.selectedKeys, ' items: ', sk)*/);
        }

        /*
        this.setState({ rows: crows, loading: false }, () => {
            //let forget = (this.props.sgf?.getForget) ? this.props.sgf.getForget() : this.props.forget;
            //console.log('............. grid loadData:froget=', forget, ' ', this.props.title);
            if (!(this.props.paState?.getForget?.()))
                this.setState({ ...(this.props.paState?.get?.()) }); //, () => console.log('......... grid remembered ', this.props.title, this.state)); //---------------------------------------------------------------------------------
 
            //if (this.props.initState && Object.keys(this.props.initState)?.length) {
            //    console.log('<- grid state << ', this.props.initState);
            //    this.setState({ rows: this.props.initState });
            //}
        });       
        */
        let crows = this.props.rows ?? this.props.dataSource ?? [];
        let ccolumns = this.props.columns ?? [];

        if (this.props.table?.length > 0 || this.props.url?.length > 0) {
            let url = this.props.url?.length > 0 ? this.props.url
                : `/api/uni/rows/${this.props.table}` + (this.props.field?.length > 0 ? `/${this.props.field}/${this.props.equalTo ?? 'null'}` : '');

            let rc = await apiGetA(url);
            let rows = this.props.url?.length > 0 ? (rc.rows ?? rc) : rc; // rc.columns?.map(x => toLowerKeys(x, false, true));
            //console.log('+=+ rows=', rows);
            if (rows?.length > 0)
                crows.push(...rows);

            if (rc.columns?.length > 0)
                ccolumns.push(...rc.columns);
            else {
                let f = await apiGetA(`/api/uni/cols/${this.props.table}/y`);
                if (f.gridCols?.length > 0) {
                    ccolumns.push(...f.gridCols);
                    if (!(this.state.parentFieldName?.length > 0))
                        this.state.parentFieldName = f.gridCols?.find(x => x.isTreeParentId === true)?.name ?? '';
                }
            }
        }
        //console.log('+++ ', crows, cols);

        if (this.props.lowerCaseFields !== false)
            crows = toLowerKeys(crows);

        if (this.props.customRowFilter)
            crows = crows.filter(x => this.props.customRowFilter(x));

        if (this.props.customRowMap)
            crows = crows.map(x => this.props.customRowMap(x));

        if (this.props.sortBy)
            crows = crows.sort((a, b) => a[this.props.sortBy] > b[this.props.sortBy] ? 1 : a[this.props.sortBy] < b[this.props.sortBy] ? -1 : 0);

        if (!(ccolumns?.length > 0) && crows.length > 0 && crows[0])
            ccolumns = Object.keys(crows[0]).map(x => ({ name: x }));

        if (this.props.lowerCaseFields !== false)
            ccolumns = ccolumns.map(x => ({ ...x, name: x.name?.toLowerCase() ?? '?' }));

        await this.refineColumns(ccolumns);
        if (this.props.unhideFields?.includes('id')) {
            let idcol = ccolumns.find(x => x.name.toLowerCase() === 'id');
            if (!idcol) {
                idcol = { name: 'id', visible: true, caption: 'آیدی' };
                ccolumns = [idcol, ...ccolumns];
            }
            else
                idcol.visible = true;
        }

        if (this.props.asyncModifyData)
            await this.props.asyncModifyData(crows, ccolumns);

        this.setState({ rows: crows, columns: ccolumns, loading: false }, () => {
            whenDone?.(crows, ccolumns);
            this.props.onDataLoaded?.(crows, ccolumns);
        });
    }

    async saveStates() {
        if (this.state.dontSaveStates || !this.props.saveState)
            return;

        let data = { pageIndex: this.state.pageIndex }; // this.state; // 

        console.log('>>> saving states for ', this.sid, ' ', data);
        await localforage.setItem(this.sid, JSON.stringify(data));
    }

    async loadStates() {
        if (this.state.dontLoadStates || !this.props.saveState)
            return;

        //this.state.dontSaveStates = this.state.dontLoadStates = true;

        let s = await localforage.getItem(this.sid);
        var data = JSON.parse(s ?? '{}');

        console.log('>>> loading states for ', this.sid, ' ', data);

        // this.setState({ pageIndex: data.pageIndex }, () => {
        //     this.state.dontSaveStates = this.state.dontLoadStates = false;
        // });

        this.setState(data);
        //this.state = { ...this.state, ...data }; //, dontLoadStates: false, dontSaveStates: false };

        //this.state.gridKey++;

        // this.forceUpdate(() => {
        //     this.state.dontSaveStates = this.state.dontLoadStates = false;
        // });
    }

    componentDidMount() {
        //console.log('>> UniGrid is mounted.');
        Promise.all([
            this.loadMetaData(),
            this.loadData(),
        ]).then(e => this.loadStates().then(e => this.forceUpdate()));
    }

    componentWillUnmount() {
        //console.log('>> UniGrid is unmounted.');
        // if (!this.state.dontSaveStates)
        //     this.saveStates();
    }

    //--------------- columns

    // onExporting(e) {
    //     const workbook = new Workbook();
    //     const worksheet = workbook.addWorksheet('Main sheet');

    //     exportDataGrid({
    //         component: e.component,
    //         worksheet,
    //         autoFilterEnabled: true,
    //     }).then(() => {
    //         workbook.xlsx.writeBuffer().then((buffer) => {
    //             saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    //         });
    //     });
    //     e.cancel = true;
    // }

    autoUpdateOrInsertToTable(e, saveToTable) {
        if (!(saveToTable?.length > 0))
            return;

        if (!(e.key > 0)) {
            //console.log('posting ', { TableName: e.table, ColumnName: e.column, ...e.newData });
            apiPost(`/api/uni/addRow/${saveToTable}`, { TableName: e.table, ColumnName: e.column, ...e.newData },
                r => {
                    if (r.error?.length > 0) {
                        console.log('operation has error: ', r.error);
                        e.cancel = true;
                        this.setState({ formMessage: r.error });
                    }
                    else
                        this.props.onSaved?.();
                });
        }
        else {
            apiPut(`/api/uni/editRow/${saveToTable}/${e.key}`, e.newData,
                r => {
                    if (r.error?.length > 0) {
                        e.cancel = true;
                        console.log('operation has error: ', r.error);
                        this.setState({ formMessage: r.error });
                    }
                    else
                        this.props.onSaved?.();
                });
        }
    }

    onRowUpdating(e) {
        console.log('update ', e.oldData, ' -> ', e.newData);

        let err = this.props.validationErrorForRow?.(e.newData);
        if (err?.length > 0) {
            e.cancel = true;
            console.log('operation has error: ', err);
            this.setState({ formMessage: err });
            return;
        }

        if (this.props.field?.length > 0)
            e.newData[this.props.field.toLowerCase()] = this.props.equalTo ?? null;

        this.props.doBeforeUpdate?.(e.newData, e.oldData);

        if (this.props.saveToTable?.length > 0) {
            e.table = e.oldData.tablename;
            e.column = e.oldData.columnname;
            this.autoUpdateOrInsertToTable(e, this.props.saveToTable);
            return;
        }
        let url = this.props.updateUrl ?? this.props.table ? `/api/uni/editRow/${this.props.table}/${e.key}` : '';
        if (!url) {
            if (!this.props.onManualUpdateRow) {
                console.log('warning: no updateUrl nor table to update row ', e);
                console.log('you can use [UniGrid props->] onManualUpdateRow={(id, newData, oldData, onError)=> {...}}');
                console.log('    or onManualUpdateRow={row=>new Promise(...)}');
            }
            else if (this.props.onManualUpdateRowP)
                this.props.onManualUpdateRowP(e.key, e.newData, e.oldData).then().catch(err => e.cancel = true);
            else
                this.props.onManualUpdateRow?.(e.key, e.newData, e.oldData, err => e.cancel = true);

            return;
        }

        apiPut(url, e.newData,
            r => {
                if (r.error?.length > 0) {
                    e.cancel = true;
                    console.log('operation has error: ', r.error);
                    this.setState({ formMessage: r.error });
                }
                else
                    this.props.onSaved?.();
            });
    }

    onRowInserting(e) {
        console.log('inserting ', e);
        let err = this.props.validationErrorForNewRow?.(e.data);

        if (err?.length > 0) {
            e.cancel = true;
            console.log('operation has error: ', err);
            this.setState({ formMessage: err });
            return;
        }

        if (this.props.field?.length > 0)
            e.data[this.props.field] = this.props.equalTo ?? null;

        this.props.doBeforeInsert?.(e.data);

        if (this.props.saveToTable?.length > 0) {
            this.autoUpdateOrInsertToTable(e, this.props.saveToTable);
            return;
        }

        let url = this.props.createUrl ?? this.props.table ? `/api/uni/addRow/${this.props.table}` : '';
        if (!url) {
            console.log('warning: no createUrl nor table to insert row ', e);
            console.log('you can use [UniGrid props->] onManualCreateRow= {(row, onError)=>{...}}');
            console.log('    or onManualCreateRowP={row=>new Promise(...)}');
            if (this.props.onManualCreateRowP)
                this.props.onManualCreateRowP(e.data).then().catch(err => e.cancel = true);
            else
                this.props.onManualCreateRow?.(e.data, err => e.cancel = true);

            return;
        }

        apiPost(url, e.data,
            r => {
                if (r.error?.length > 0) {
                    console.log('operation has error: ', r.error);
                    e.cancel = true;
                    this.setState({ formMessage: r.error });
                }
                else {
                    //this.reloadRows();
                    this.props.onSaved?.();
                }
            },
            err => {
                console.log('operation has error: ', err);
                e.cancel = true;
                this.setState({ formMessage: err });
            });
    }

    reloadRows = () => {
        this.state.rows = undefined;
        this.loadData().then();
    }

    onRowRemoving(e) {
        console.log('deleting ', e);
        let url = this.props.deleteUrl ?? this.props.table ? `/api/uni/deleteRow/${this.props.table}/${e.key}` : '';
        if (!url) {
            console.log('warning: no deleteUrl nor table to delete row ', e);
            console.log('you can use [UniGrid props->] onManualDeleteRow= id=>{...}');
            this.props.onManualDeleteRow?.(e.key);
            return;
        }

        apiDelete(`/api/uni/deleteRow/${this.props.table}/${e.key}`,
            r => {
                e.cancel = r.error !== '';
                if (r.error === '')
                    this.props.onSaved?.();
            });
    }

    renderTitleBox = () => {
        return (
            <Inline spacing='0.5rem' //style={{ margin: '1rem 1rem 0 0', padding: '0' }}
            >
                {this.props.putItemsBeforeTitle}

                {this.props.favButton !== false && this.state.topMenuItem?.id > 0 &&
                    <Button key={'favbtn' + this.state.favKey + '_' + this.state.topMenuItem.isfavorite} width={35}
                        hint={this.state.topMenuItem.isfavorite ? 'حذف از برگزیده ها' : 'افزودن به برگزیده ها'}
                        onClick={() => Header.setFavoriteMenu(this.state.topMenuItem,
                            this.state.topMenuItem.isfavorite ? 'delete' : 'add',
                            () => {
                                //this.state.topMenuItem.isfavorite = !this.state.topMenuItem.isfavorite;
                                this.state.favKey++;
                                this.forceUpdate();
                            }
                        )}
                    >
                        <FontAwesomeIcon key={'favicon' + this.state.favKey + '_' + this.state.topMenuItem.isfavorite}
                            icon={this.state.topMenuItem.isfavorite ? 'bookmark' : 'star'} beatFade={!this.state.topMenuItem.isfavorite}
                        />
                    </Button>
                }

                {!this.props.noMetaGrid &&
                    <Button onClick={() => this.setState({
                        showUniSettings: !this.state.showUniSettings,
                        gridKey: this.state.gridKey + 1,
                    })}
                        hint='تنظیمات ستونها'
                    >
                        <FontAwesomeIcon icon={this.state.showUniSettings ? 'rectangle-list' : 'table-list'}
                            rotation={180}
                        />
                    </Button>
                }

                {this.state.topMenuItem?.id > 0 &&
                    <Button hint='انتساب جدول/کمپوننت به منو'
                        onClick={() => this.setState({ showAssigning: !this.state.showAssigning })}
                    >
                        <FontAwesomeIcon icon='ellipsis' />
                    </Button>
                }

                {this.state.topMenuItem?.id > 0 &&
                    <img src={'/images/menu/' + this.state.topMenuItem.id + '.png'} width={27} alt='' />
                }

                {(this.props.title || this.state.topMenuItem?.pagetitle) &&
                    <div style={{ fontWeight: '700', fontSize: '1.3rem' }}>{this.state.topMenuItem?.pagetitle ?? this.props.title}</div>
                }
                {this.state.groupBy?.length > 0 && this.props.noGroupButtons !== true &&
                    <>
                        <Button /*icon='expand'*/ hint='باز کردن گروهها' /* text='تنظیمات ستونها'*/
                            onClick={() => this.setState({ autoExpandAll: true })}
                        >
                            <FontAwesomeIcon icon='circle-chevron-down' />
                        </Button>
                        <Button /*icon='collapse'*/ hint='فشرده کردن گروهها' /* text='تنظیمات ستونها'*/
                            onClick={() => this.setState({ autoExpandAll: false })}
                        >
                            <FontAwesomeIcon icon='circle-chevron-up' />
                        </Button>
                    </>
                }
                {this.props.putItemsAfterTitle}
            </Inline>
        );
    };

    // putItemOnToolbarBefore = item => {
    //     if (!item)// && !this.props.putTitleBoxInToolbar)// && !(this.props.title?.length > 0))
    //         return;

    //     let me = document.querySelector("#uniGridContainer_" + this.sid + " > div > div.dx-"
    //         + (this.isTreeList ? 'treelist' : 'datagrid') + "-header-panel > div > div > div.dx-toolbar-before");

    //     if (!me)
    //         return;

    //     //let titleOnToolbarElement = `<div style='display:flex;align-items:center;height:100%;font-weight:700;padding:0 1rem;' class='${this.props.titleOnToolbar_className ?? ''}'>${this.props.titleOnToolbar}</div>`;
    //     //me.innerHTML = renderToPipeableStream(titleOnToolbarElement);

    //     let cmp = (
    //         <div style={{
    //             display: 'flex',
    //             alignItems: 'center',
    //             height: '100%',
    //             fontWeight: '700',
    //             padding: '0 1rem',
    //         }} className={this.props.toolbarItemsNear_className ?? ''}
    //         >
    //             {/* {this.props.table?.length > 0 && this.props.noMetaGrid !== true && window.user?.isadmin === true &&
    //                 <span style={{ marginLeft: '5px' }}>
    //                     <Button icon='detailslayout' hint='تنظیمات ستونها' // text='تنظیمات ستونها'
    //                         onClick={() => this.setState({ showUniSettings: !this.state.showUniSettings })}
    //                     />
    //                 </span>
    //             } */}
    //             {/* {this.props.title?.length > 0 &&
    //                 <span className='unigrid-title'>{this.props.title}</span>
    //             } */}

    //             {/* {this.props.putTitleBoxInToolbar && this.renderTitleBox()} */}

    //             {item ?? ''}
    //         </div>
    //     );

    //     ReactDOM.createRoot(me)?.render?.(cmp, me);
    // }

    componentDidUpdate() {
        //this.putItemOnToolbarBefore(this.props.toolbarItemsNear);
    }

    renderGroupCell = (cell, col) => (
        this.props.groupCellRender?.(cell, col) ??
        <div style={{ display: 'inline-flex', alignItems: 'center', ...this.props.groupCellStyle }}>
            {this.props.groupCellItemsBefore && <span>{this.props.groupCellItemsBefore?.(cell, col)}</span>}
            <span style={{ fontWeight: '700' }}>{col.caption}: {cell.displayValue/*cell.value*/}</span>
            {this.props.groupCellItemsAfter && <span>{this.props.groupCellItemsAfter?.(cell, col)}</span>}
        </div>
    );


    render() {
        if (!this.props.ignoreUserCheck && !(window.user?.id > 0))
            return (
                <div id={this.props.id ?? this.sid + '_wrapper'}
                    className='unigrid-wrapper'
                    style={{
                        width: this.props.width > 0 ? this.props.width + 'px' : (this.props.width ?? 'auto'),
                        height: this.props.height > 0 ? this.props.height + 'px' : (this.props.height ?? 'auto'),
                        ...this.props.wrapperStyle
                    }}
                >
                    {invalidUser_component}
                </div>
            );

        if (this.state.loading)
            return (
                <>
                    <div className='unigrid-wrapper'
                        style={{ ...this.props.wrapperStyle, padding: '1rem',/*, position: 'relative'*/ }}>
                        {this.renderTitleBox()}

                        {this.state.showAssigning && this.state.topMenuItem?.id > 0 &&
                            <div style={{
                                padding: '1rem',
                                //display: this.state.topMenuItem?.id > 0 && this.state.showAssigning ? 'block' : 'none'
                            }}>
                                <PageAssigner menuItem={this.state.topMenuItem} textBoxWidth='50rem'
                                    onSaved={this.forceUpdate}
                                    onClose={() => this.setState({ showAssigning: false })}
                                />
                            </div>
                        }
                    </div>

                    <div className='unigrid-wrapper'
                        style={{
                            //position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            //top: '0px',
                            //left: '0px',
                            width: this.props.width > 0 ? this.props.width + 'px' : (this.props.width ?? 'inherit'),
                            height: this.props.height > 0 ? this.props.height + 'px' : (this.props.height ?? 'inherit'),
                            //...this.props.wrapperStyle
                            //zIndex: '900'
                        }}
                    >
                        <Spinner />
                    </div>
                </>
            );

        let columnsInfo = (this.state.columns ?? Object.keys(this.state.rows?.[0] ?? [{ name: 'name' }])?.map(x => ({ name: x })))
            ?.filter(x => !x.name?.startsWith('pa_') && !x.name?.startsWith('__')
                //&& !x.name?.toLowerCase()?.endsWith('date') ///////////////////////////
                //&& x.visible != false //////////////////////////////////
            );

        if (this.props.hideFields?.length > 0)
            columnsInfo.forEach(x => { //////////////////////////
                x.visible = x.visible !== false && this.props.hideFields.findIndex(h => h.toLowerCase() === x.name.toLowerCase()) < 0;
            });

        // let colCount = 0;
        // columnsInfo.forEach(x => {
        //     if (x.visible)
        //         colCount += (x.colSpan > 0 ? colSpan : 1);
        // });
        // colCount ??= 1;


        let parentFieldName = this.state.parentFieldName;
        this.isTreeList = parentFieldName?.length > 0 && this.state.forceUseGrid !== true;
        let firstVisibleColumnInfo = columnsInfo?.find(c => c.visible !== false);

        let columns = columnsInfo?.map(x =>
            <Column key={x.name} name={x.name} dataField={x.name} caption={x.caption ?? x.name} visible={x.visible}
                allowEditing={x.readOnly !== true && x.readonly !== true} colSpan={x.colSpan ?? 0}
                //fixed={this.isTreeList && x.name === firstVisibleColumnInfo?.name}
                lookup={defLookupDataSource(x.lookup)} //calculateDisplayValue={c => ...} calculateCellValue=...
                dataType={x.format === 'bool' || x.format === 2 ? 'boolean' : x.format === 'number' ? 'number' : undefined}
                grouped={this.state.groupBy?.toLowerCase() === x.name?.toLowerCase()}

                //width={this.props.width * x.colSpan / colCount}
                editCellComponent={
                    x.format === 'tarikh' || x.format === 3 /*|| x.name?.toLowerCase()?.endsWith('per')*/ ? TarikhPicker
                        : x.format === 'tarikhtime' ? TarikhTimePicker
                            : x.format === 'time' ? TimePickerHHMM : undefined
                }
                cellRender={this.props.customCellRender && Object.keys(this.props.customCellRender)?.includes?.(x.name)
                    ? this.props.customCellRender[x.name] : undefined
                }
                // groupCellRender={cell => (
                //     this.props.groupCellRender?.(cell, x) ??
                //     <div style={{ display: 'inline-flex', alignItems: 'center', ...this.props.groupCellStyle }}>
                //         {this.props.groupCellItemsBefore && <span>{this.props.groupCellItemsBefore?.(cell, x)}</span>}
                //         <span style={{ fontWeight: '700' }}>{x.caption}: {cell.value}</span>
                //         {this.props.groupCellItemsAfter && <span>{this.props.groupCellItemsAfter?.(cell, x)}</span>}
                //     </div>
                // )}

                groupCellRender={cell => this.renderGroupCell(cell, x)}
                {...x.dxProps}
            />
        );

        if (!(columns?.length > 0))
            return <div style={{ opacity: '0.5', margin: '1rem' }}>اطلاعات در دسترس نیست</div>;

        const key = this.props.keyExpr ?? this.state.columns?.find(x => x.name === 'id') ? 'id' : this.state.columns?.find(x => x.name === 'key') ? 'key' : 'id'; // this.state.columns?.[0]?.name;
        let formColCount = this.props.formColCount ?? columns.length > 15 ? 4 : columns.length > 10 ? 3 : 2; // Math.floor(Math.sqrt(2 + (columns?.length ?? 0)));
        let formWidth = `${formColCount * 20}%`;

        let prp = {};
        Object.keys(this.props).filter(x => x !== 'id' && x !== 'columns' && x !== 'rows' && x !== 'dataSource' && x !== 'onFocusedRowChanged').forEach(x => prp[x] = this.props[x]);

        let btnCellStyle = {
            fontSize: '18px',
            textAlign: 'center', width: '100%', height: '100%',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            cursor: 'pointer',
        };

        let firstVisibleColumn = columns.find(c => c.key === firstVisibleColumnInfo?.name);

        return (
            <div id={this.props.id ?? this.sid + '_wrapper'} className='unigrid-wrapper' key={'outerDiv' + this.state.outerDivKey}
                style={{ ...this.props.wrapperStyle/*, position: 'relative'*/ }}>

                {(this.props.putTitleBoxInToolbar === false || this.props.noToolbar === true) && this.renderTitleBox()}

                {this.state.showAssigning && this.state.topMenuItem?.id > 0 &&
                    <div style={{
                        padding: '1rem',
                        //display: this.state.topMenuItem?.id > 0 && this.state.showAssigning ? 'block' : 'none'
                    }}>
                        <PageAssigner menuItem={this.state.topMenuItem} textBoxWidth='50rem'
                            onSaved={this.forceUpdate}
                            onClose={() => this.setState({ showAssigning: false })}
                        />
                    </div>
                }


                {((this.props.table?.length > 0 || this.props.sid?.length > 0) && this.props.noMetaGrid !== true) && // || this.props.title?.length > 0) &&
                    <div style={{ paddingBottom: '5px' /*position: 'relative', top: '40px', right: '5px', zIndex: '2'*/ }}>
                        {(this.props.table?.length > 0 || this.props.sid?.length > 0) && this.props.noMetaGrid !== true && window.user?.isadmin === true &&
                            <span style={{ marginLeft: '5px' }}>
                                {/* {this.props.hideMetaGridButton !== true &&
                                    <Button icon='detailslayout' hint='تنظیمات ستونها' 
                                        onClick={() => this.setState({ showUniSettings: !this.state.showUniSettings })}
                                    />
                                } */}
                                {/* {this.state.groupBy?.length > 0 &&
                                    <Button icon='expand' hint='باز کردن گروهها' 
                                        onClick={() => this.setState({ autoExpandAll: true })}
                                    />
                                }
                                {this.state.groupBy?.length > 0 &&
                                    <Button icon='collapse' hint='فشرده کردن گروهها' 
                                        onClick={() => this.setState({ autoExpandAll: false })}
                                    />
                                } */}
                                {this.state.showUniSettings &&
                                    <UniMetaGrid key={this.state.showUniSettings + 'ugs'}
                                        title={this.props.title ?? this.state.topMenuItem?.pagetitle}
                                        sourceTable={this.props.table} sourceSid={this.props.url?.length > 0 ? this.props.sid : ''}
                                        sourceColNames={this.props.url?.length > 0 ? this.state.columns?.map(x => x.name) : []}
                                        onSaved={e => {
                                            this.state.rows = undefined;
                                            this.state.columns = undefined;
                                            this.loadData().then(() => this.setState({ gridKey: this.state.gridKey + 1 }));
                                        }}

                                        onCloseMe={() => this.setState({ showUniSettings: false })}
                                    />}
                            </span>
                        }
                        {/* {false && this.props.title?.length > 0 && <span className='unigrid-title'>{this.props.title}</span>} */}
                    </div>
                }

                {this.isTreeList ?
                    <TreeList id={"uniGridContainer_" + this.sid} ref={el => this.grid = el}
                        key={'tree' + this.state.gridKey + "|" + this.props.table + this.props.url}
                        autoExpandAll={false} //expandedRowKeys={['Company_Parts__']}
                        dataSource={this.state.rows}
                        parentIdExpr={parentFieldName}
                        rtlEnabled={!this.props.ltr}
                        keyExpr={key}
                        width={this.props.width} height={this.props.height} maxHeight={window.innerHeight * 0.9} //??
                        headerFilter={this.props.headerFilter !== false}
                        allowUpdating={this.props.allowDeleting !== false && this.props.readOnly !== true}
                        allowAdding={this.props.allowAdding !== false && this.props.readOnly !== true}
                        allowDeleting={this.props.allowDeleting !== false && this.props.readOnly !== true}
                        showColumnLines={true} showBorders={this.props.showBorders !== false}
                        columnAutoWidth={this.props.columnAutoWidth !== false}
                        showRowLines={true}
                        selectedRowKeys={this.props.selectedKeys}
                        onSelectedRowKeysChange={s => {
                            this.selectedKeys = s;
                            this.props.onSelectedKeysChanged?.(s);
                        }}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={this.props.rowAlternationEnabled}
                        onRowRemoving={this.onRowRemoving}
                        onRowUpdating={e => this.onRowUpdating(e)}
                        onRowInserting={this.onRowInserting}
                        focusedRowKey={this.state.focusedRow?.id}
                        focusedRowEnabled={true} onFocusedRowChanged={e => {
                            this.state.focusedRow = e.row?.data;
                            this.state.contextMenuItems?.forEach(x => x.visible = this.props.contextMenuItemVisibleIf?.(x, e.row?.data) ?? true);
                            this.props.onFocusedRowChanged?.(e.row?.data);
                            this.forceUpdate();
                            //console.log('-> focused row: ', e.row?.data);
                            //this.setState({ focusedRow: e.row.data });
                        }}

                        //onSaved={r =>this.forceUpdate()}
                        {...prp}
                        onContentReady={e => {
                            //this.putItemOnToolbarBefore(this.props.toolbarItemsNear);
                            this.state.loading = false;
                            this.props.onContentReady?.(e);
                        }}
                    >
                        {this.props.multiSelect && this.props.allowChangeSelection !== false && <Selection mode='multiple' recursive={true} showCheckBoxesMode='always' />}
                        {false && <Selection mode="single" />}
                        <Paging defaultPageSize={15} pageIndex={this.state.pageIndex} />
                        <SearchPanel visible={this.props.noToolbar !== true} placeholder={'جستجو'} searchVisibleColumnsOnly />
                        <HeaderFilter visible={this.props.headerFilter !== false} />
                        {this.props.filterPanel &&
                            <TFilterPanel visible={this.props.filterPanel !== false}
                                texts={{
                                    clearFilter: 'حذف فیلتر',
                                    createFilter: 'ایجاد فیلتر',
                                    filterEnabledHint: 'فیلتر فعال است'
                                }}
                            />
                        }
                        <Scrolling mode='standard' />
                        <FilterRow visible={this.props.filterRow !== false} showOperationChooser={false} />
                        <FilterBuilderPopup />
                        <Export enabled={true} formats={['pdf', 'word', 'excel']} allowExportSelectedData={true} />
                        <Sorting mode='single' ascendingText='مرتب سازی صعودی' descendingText='مرتب سازی نزولی' clearText='حذف مرتب سازی' />
                        <TStateStoring enabled={false && this.props.saveState} type='localStorage' storageKey={this.sid} />
                        {this.props.allowEditing !== false &&
                            <Editing
                                mode={this.props.editingMode ?? 'popup'}
                                allowUpdating={this.props.allowUpdating !== false && this.props.readOnly !== true}
                                allowAdding={this.props.allowAdding !== false && this.props.readOnly !== true}
                                allowDeleting={this.props.allowDeleting !== false && this.props.readOnly !== true}
                                useIcons={true}>
                                <Popup title={"ورود اطلاعات " + (this.props.title ?? '')} showTitle={true} width={formWidth} height='fit-content'
                                    resizeEnabled={true} shading={true} shadingColor='rgba(0,0,0,0.5)' />

                                {this.props.renderForm ? this.props.renderForm(this.state, this.grid) :
                                    <Form ref={el => this.form = el} key={this.state.formMessage} colCount={formColCount}
                                        id={this.props.title + 'form'}
                                    >
                                        {columnsInfo?.filter(x => x.name !== '_sequence' && x.visible !== false)?.map(x =>
                                            <Form_Item name={x.name} dataField={x.name} key={x.name}
                                                lookup={defLookupDataSource(x.lookup)}
                                                label={{ text: x.caption ?? x.name }}
                                                colSpan={x.colSpan > 0 ? x.colSpan : 1}
                                                editorOptions={{ readOnly: x.readOnly === true || x.readonly === true }}
                                            />)
                                        }

                                        {this.state.formMessage?.length > 0 &&
                                            <SimpleItem colSpan={2}>
                                                <div style={{ color: 'red', background: 'yellow' }}>
                                                    {this.state.formMessage}
                                                </div>
                                            </SimpleItem>
                                        }
                                    </Form>
                                }
                                {/* <Form ref={el => this.form = el} key={this.state.formMessage} colCount={formColCount}>
                                {columnsInfo.map(x =>
                                    x.lookup?.length > 0 ?
                                        <Item dataField={x.name} colSpan={x.colSpan > 0 ? x.colSpan : 1}
                                            editorOptions={{ readOnly: x.readOnly === true || x.readonly === true }}>
                                        </Item>
                                        :
                                        <Item dataField={x.name} colSpan={x.colSpan > 0 ? x.colSpan : 1}
                                            editorOptions={{ readOnly: x.readOnly === true || x.readonly === true }}
                                        />)

                                    //dataField="Notes"
                                    //editorType="dxTextArea"
                                    //colSpan={2}
                                    //editorOptions={notesEditorOptions}
                                }

                                {this.state.formMessage?.length > 0 &&
                                    <SimpleItem colSpan={2}>
                                        <div style={{ color: 'red', background: 'yellow' }}>
                                            {this.state.formMessage}
                                        </div>
                                    </SimpleItem>}
                            </Form> */}
                            </Editing>
                        }
                        {this.props.allowChangeSelection === false && this.props.multiSelect &&
                            <Column caption='' calculateDisplayValue={row => this.props.selectedKeys?.includes(row.id) ? '✓' : ''}
                                width='4rem' alignment='left'
                            />
                        }
                        {this.props.noToolbar !== true &&
                            <TToolbar>
                                {this.props.putTitleBoxInToolbar !== false &&
                                    <TToolbarItem location='before' render={this.renderTitleBox}
                                    />
                                }
                                {this.props.addToToolbar &&
                                    [...(this.props.addToToolbar?.length > 1 ? this.props.addToToolbar : [this.props.addToToolbar])
                                        .map(x => <TToolbarItem>{x}</TToolbarItem>)]
                                }

                                <TToolbarItem location='after'
                                    render={() => {
                                        return (
                                            <div style={{ marginLeft: '5px', height: '100%' }}>
                                                <Button onClick={e => this.setState({ forceUseGrid: true })}
                                                    hint='نمایش جدولی' height='100%'
                                                >
                                                    <FontAwesomeIcon icon='list' fontSize='1rem' rotation={180} />
                                                </Button>
                                            </div>
                                        );
                                    }}
                                />

                                {this.props.allowAdding !== false && this.props.readOnly !== true && <GToolbarItem name='addRowButton' />}
                                <TToolbarItem name='exportButton' />
                                <TToolbarItem name='revertButton' />
                                <TToolbarItem name='searchPanel' />
                            </TToolbar>
                        }

                        {/*e.component.columnOption("command:edit", "visibleIndex", -1)  */}

                        {this.props.readOnly !== true &&
                            <Column type="buttons" width='5rem'>
                                <Button name="edit" />
                                <Button name="delete" />
                            </Column>
                        }
                        {firstVisibleColumn}

                        {this.props.buttonColumns?.filter(x => x.locateAtBefore)?.map(b =>
                            <Column caption={b.caption} width={b.width ?? '3rem'} //'fit-content'} //columnAutoWidth={false}
                                cellRender={c => (
                                    b.useButton ?
                                        <Button width={b.width ?? '100%'} rowData={c.data}
                                            icon={b.iconOfRow?.(c.data) ?? b.icon} text={b.textOfRow?.(c.data) ?? b.text}
                                            disabled={b.disableIfRow?.(c.data) === true}
                                            {...b.props} className='ka-no-padding' style={this.props.buttonStyle}
                                            onClick={e => b.onClick?.(c.data)}
                                        /> // onClick={e => b.onClick?.(e.component._props.rowData)}
                                        :
                                        <i className={'dx-icon-' + (b.iconOfRow?.(c.data) ?? b.icon) + ' ka-no-padding'}
                                            style={btnCellStyle} onClick={e => b.onClick?.(c.data)}
                                        >{b.textOfRow?.(c.data) ?? b.text}
                                        </i>)
                                }>
                            </Column>)
                        }

                        {columns.filter(x => x.key !== firstVisibleColumn?.key)}

                        {this.props.customColumns?.map(cc =>
                            <Column caption={cc.caption} width={cc.width ?? 'fit-content'}
                                cellRender={cc.cellRender}
                                {...cc.dxProps}
                            />)
                        }

                        {this.props.buttonColumns?.filter(x => !x.locateAtBefore)?.map(b =>
                            <Column caption={b.caption} width={b.width ?? '3rem'} //'fit-content'} //columnAutoWidth={false}
                                cellRender={c => (
                                    b.useButton ?
                                        <Button width={b.width ?? '100%'} rowData={c.data}
                                            icon={b.iconOfRow?.(c.data) ?? b.icon} text={b.textOfRow?.(c.data) ?? b.text}
                                            disabled={b.disableIfRow?.(c.data) === true}
                                            {...b.props} className='ka-no-padding' style={this.props.buttonStyle}
                                            onClick={e => b.onClick?.(c.data)}
                                        /> // onClick={e => b.onClick?.(e.component._props.rowData)}
                                        :
                                        <i className={'dx-icon-' + (b.iconOfRow?.(c.data) ?? b.icon) + ' ka-no-padding'}
                                            style={btnCellStyle} onClick={e => b.onClick?.(c.data)}
                                        >{b.textOfRow?.(c.data) ?? b.text}
                                        </i>)
                                }>
                            </Column>)
                        }
                        {/* 
                        {this.props.readOnly !== true &&
                            <Column type="buttons" width='5rem'>
                                <Button name="edit" />
                                <Button name="delete" />
                            </Column>
                        } */}
                    </TreeList>
                    : //=======================================================================================================
                    <DataGrid id={this.props.id ?? "uniGridContainer_" + this.sid}
                        ref={el => this.grid = el}
                        key={'grid' + this.state.gridKey + '_' + this.state.rows?.length + this.props.table + this.props.url}
                        dataSource={this.state.rows}
                        rtlEnabled={!this.props.ltr}
                        width={this.props.width} height={this.props.height}
                        keyExpr={key}
                        headerFilter={true}
                        showBorders={this.props.showBorders !== false}
                        allowUpdating={this.props.allowDeleting !== false && this.props.readOnly !== true}
                        allowAdding={this.props.allowAdding !== false && this.props.readOnly !== true}
                        allowDeleting={this.props.allowDeleting !== false && this.props.readOnly !== true}
                        columnAutoWidth={this.props.columnAutoWidth !== false}
                        selectedRowKeys={this.props.selectedKeys}
                        onSelectedRowKeysChange={s => {
                            this.selectedKeys = s;
                            this.props.onSelectedKeysChanged?.(s);
                        }}
                        showColumnLines={true}
                        showRowLines={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={this.props.rowAlternationEnabled}
                        onSaving={e => this.props.onSaving?.(e)}
                        onSaved={e => this.props.onSaved?.(e)}
                        onRowRemoving={this.onRowRemoving}
                        onRowUpdating={e => this.onRowUpdating(e)}
                        onRowInserting={e => this.onRowInserting(e)}

                        // onSelectionChanged={e => {
                        //     console.log('=======================');
                        //     if (this.props.allowChangeSelection === false && this.grid)
                        //         this.grid.set = this.state.selectedKeys;
                        // }}
                        maxHeight={window.innerHeight * 0.9} //??
                        //onRowInserted={e => console.log('inseted: ', e)}                      
                        // onEditorPrepared={e => {
                        //     console.log('editor prepared: ', e);
                        //     if (this.props.table?.length > 0)
                        //         return;
                        //     e.lookup = false;
                        //     e.dataField = e.name;
                        //     e.dataType = 'string';
                        // }}
                        focusedRowKey={this.state.focusedRow?.id}//{this.props.focusedRowKey}
                        focusedRowEnabled={true}
                        onFocusedRowChanged={e => {
                            this.state.focusedRow = e.row?.data;
                            this.state.contextMenuItems?.forEach(x => x.visible = this.props.contextMenuItemVisibleIf?.(x, e.row?.data) ?? true);
                            this.props.onFocusedRowChanged?.(e.row?.data);
                            //if (!this.props.onFocusedRowChanged)
                            this.forceUpdate();
                            //     // if (!(e.row?.data))
                            //     //     return;

                            //     this.state.contextMenuItems?.forEach(x => x.visible = this.props.contextMenuItemVisibleIf?.(x, e.row?.data) ?? true);
                            //     this.setState({ focusedRow: e.row?.data }, () => this.props.onFocusedRowChanged?.(e.row?.data));
                            //     //this.props.onFocusedRowChanged?.(e.row?.data);
                            //     //this.forceUpdate();
                            //     //console.log('-> focused row: ', e.row?.data);
                            //     //this.setState({ focusedRow: e.row.data });
                        }}
                        {...prp} ////////////////////////
                        onContentReady={e => {
                            //this.putItemOnToolbarBefore(this.props.toolbarItemsNear);
                            this.state.loading = false;
                            this.props.onContentReady?.(e);

                            //this.setState({ gridKey: this.state.gridKey + 1, loading: false })
                        }}
                    //onSaved={r =>this.forceUpdate()}
                    >
                        {this.props.multiSelect && this.props.allowChangeSelection !== false && <Selection mode='multiple' showCheckBoxesMode='always' />}
                        {false && <Selection mode='single' />}
                        <Paging /*defaultPageSize={15}*/ pageIndex={this.state.pageIndex}
                            onPageIndexChange={pageIndex => this.setState({ pageIndex }, this.saveStates)}
                        />
                        {this.state.groupBy?.length > 0 && <Grouping key={this.state.groupBy} autoExpandAll={this.state.autoExpandAll} />}
                        <SearchPanel visible={this.props.noToolbar !== true} placeholder={'جستجو'} />
                        <HeaderFilter visible={this.props.headerFilter !== false} />
                        {this.props.filterPanel &&
                            <GFilterPanel visible={this.props.filterPanel !== false}
                                texts={{
                                    clearFilter: 'حذف فیلتر',
                                    createFilter: 'ایجاد فیلتر',
                                    filterEnabledHint: 'فیلتر فعال است'
                                }}
                            />
                        }
                        {this.props.filterBuilder !== false && <FilterBuilderPopup />}
                        <FilterRow visible={this.props.FilterRow !== false} />
                        <Scrolling mode='standard' rowRenderingMode scrollByThumb />
                        {this.props.groupBy?.length > 0 && <Grouping allowCollapsing autoExpandAll={this.props.autoExpandAll} />}

                        {false && <ScrollBar />}
                        <Export enabled={this.props.noToolbar !== true} formats={['pdf', 'word', 'excel']} allowExportSelectedData={true} />
                        {this.props.noToolbar !== true &&
                            <GToolbar>
                                {this.props.putTitleBoxInToolbar !== false &&
                                    <GToolbarItem location='before' render={this.renderTitleBox}
                                    />
                                }
                                {this.props.addToToolbar &&
                                    [...(this.props.addToToolbar?.length > 1 ? this.props.addToToolbar : [this.props.addToToolbar])
                                        .map(x => <GToolbarItem>{x}</GToolbarItem>)]
                                }

                                {this.state.parentFieldName?.length > 0 &&
                                    <GToolbarItem location='after'
                                        render={() => {
                                            return (
                                                <div style={{ marginLeft: '5px', height: '100%' }}>
                                                    <Button onClick={e => this.setState({ forceUseGrid: false })}
                                                        hint='نمایش درختی' height='100%'
                                                    >
                                                        <FontAwesomeIcon icon='chart-simple' fontSize='1rem' rotation={270} />
                                                    </Button>
                                                </div>
                                            );
                                        }}
                                    />
                                }

                                {this.props.allowAdding !== false && this.props.readOnly !== true &&
                                    <GToolbarItem name='addRowButton' />
                                }
                                <GToolbarItem name='exportButton' />
                                <GToolbarItem name='revertButton' />
                                <GToolbarItem name='searchPanel' />
                            </GToolbar>
                        }

                        <Sorting mode='single' ascendingText='مرتب سازی صعودی' descendingText='مرتب سازی نزولی' clearText='حذف مرتب سازی' />
                        <GStateStoring enabled={false && this.props.saveState} storageKey={this.sid} type='custom' //type='localStorage' 
                            customSave={this.saveStates}
                            customLoad={this.loadStates}
                        />

                        {this.props.allowEditing !== false &&
                            <Editing
                                mode={this.props.editingMode ?? 'popup'}
                                allowUpdating={this.props.allowUpdating !== false && this.props.readOnly !== true}
                                allowAdding={this.props.allowAdding !== false && this.props.readOnly !== true}
                                allowDeleting={this.props.allowDeleting !== false && this.props.readOnly !== true}
                                useIcons={true}>

                                <Popup title={"ورود اطلاعات " + (this.props.title ?? '')} showTitle={true} width={formWidth} height='fit-content'
                                    resizeEnabled={true} shading={true} shadingColor='rgba(0,0,0,0.5)' />

                                {this.props.renderForm ? this.props.renderForm(this.state, this.grid) :
                                    <Form ref={el => this.form = el} key={this.state.formMessage} colCount={formColCount}
                                        id={this.props.title + 'form'} height={this.props.formHeight ?? '70%'}
                                    >
                                        {columnsInfo?.filter(x => x.name !== '_sequence' && x.visible !== false)?.map(x =>
                                        (
                                            // x.lookup?.length > 0 ?
                                            // <Form_Item name={x.name} dataField={x.name} key={x.name}
                                            //     //lookup={defLookupDataSource(x.lookup)}
                                            //     label={{ text: x.caption ?? x.name }}
                                            //     colSpan={x.colSpan > 0 ? x.colSpan : 1}
                                            // // editorOptions={{
                                            // //     readOnly: x.readOnly === true || x.readonly === true,
                                            // //     //editCellComponent: x.format === 'tarikh' || x.format === 3 ? TarikhPicker : undefined
                                            // // }}
                                            // // editorType={x.lookup?.length > 0 ? 'dxLookup' : undefined}
                                            // >
                                            //     <SelectBox dataSource={x.lookup} valueExpr='id' displayExpr='summary'
                                            //         //scrollByThumb
                                            //         //dropDownOptions={{ maxHeight: 500 }}
                                            //         readOnly={x.readOnly === true || x.readonly === true}
                                            //     />
                                            // </Form_Item> :
                                            <Form_Item name={x.name} dataField={x.name} key={x.name}
                                                lookup={defLookupDataSource(x.lookup)}
                                                label={{ text: x.caption ?? x.name }}
                                                colSpan={x.colSpan > 0 ? x.colSpan : 1}
                                                editorOptions={{
                                                    readOnly: x.readOnly === true || x.readonly === true,
                                                    //editCellComponent: x.format === 'tarikh' || x.format === 3 ? TarikhPicker : undefined
                                                }}
                                            //editorType={x.lookup?.length > 0 ? 'dxLookup' : undefined}
                                            />
                                        ))
                                        }

                                        {this.state.formMessage?.length > 0 &&
                                            <SimpleItem colSpan={2}>
                                                <div style={{ color: 'red', background: 'yellow' }}>
                                                    {this.state.formMessage}
                                                </div>
                                            </SimpleItem>}
                                    </Form>}
                            </Editing>
                        }

                        {this.props.allowChangeSelection === false && this.props.multiSelect &&
                            <Column caption='' calculateDisplayValue={row => this.props.selectedKeys?.includes(row.id) ? '✓' : ''}
                                width='2rem' alignment='center'
                            />
                        }
                        {/*this.props.table?.length > 0 &&*//**/ this.props.sequenceColumn !== false &&
                            <Column calculateDisplayValue={x => 1 + this.state.rows?.findIndex(r => r.id === x.id)}
                                name='_sequence' key='_sequence' dataField={undefined}
                                allowEditing={false} formItem={false}
                                width='5rem' alignment='center' caption='ردیف' allowSorting={true} fixedPosition={true} />
                        }

                        {this.props.rightButtonColumns?.map(b =>
                            <Column caption={b.caption} width={b.width ?? 'fit-content'}
                                cellRender={c => (b.useButton ?
                                    <Button width={b.width ?? '100%'} rowData={c.data}
                                        hint={b.hintOfRow?.(c.data) ?? b.hint}
                                        icon={b.iconOfRow?.(c.data) ?? b.icon}
                                        text={b.textOfRow?.(c.data) ?? b.text}
                                        disabled={b.disableIfRow?.(c.data) === true}
                                        {...b.props} className='ka-no-padding' style={{ ...this.props.buttonStyle }}
                                        onClick={() => b.onClick?.(c.data)}
                                        showBorders={false}
                                    /> // onClick={e => b.onClick?.(e.component._props.rowData)}
                                    :
                                    <i className={'dx-icon-' + (b.iconOfRow?.(c.data) ?? b.icon) + ' ka-no-padding'}
                                        style={btnCellStyle} onClick={e => b.onClick?.(c.data)}
                                    >{b.textOfRow?.(c.data) ?? b.text}
                                    </i>)
                                }>
                            </Column>
                        )}

                        {this.props.readOnly !== true &&
                            <Column type="buttons" width='5rem'>
                                <Button name="edit" />
                                <Button name="delete" />
                            </Column>
                        }
                        {this.props.buttonColumns?.filter(x => x.locateAtBefore)?.map(b => //b &&
                            <Column caption={b.caption} width={b.width ?? 'fit-content'}
                                cellRender={c => (b.useButton ?
                                    <Button width={b.width ?? '100%'} rowData={c.data}
                                        {...b.props}
                                        hint={b.hintOfRow?.(c.data) ?? b.hint}
                                        icon={b.iconOfRow?.(c.data) ?? b.icon}
                                        text={b.textOfRow?.(c.data) ?? b.text}
                                        disabled={b.disableIfRow?.(c.data) === true}
                                        className='ka-no-padding' style={{ ...this.props.buttonStyle }}
                                        showBorders={false}
                                        onClick={e => b.onClick?.(c.data)}
                                    /> // onClick={e => b.onClick?.(e.component._props.rowData)}
                                    :
                                    <i className={'dx-icon-' + (b.iconOfRow?.(c.data) ?? b.icon) + ' ka-no-padding'}
                                        style={btnCellStyle} onClick={e => b.onClick?.(c.data)}
                                    >{b.textOfRow?.(c.data) ?? b.text}
                                    </i>)
                                }>
                            </Column>
                        )}

                        {columns}

                        {this.props.customColumns?.map(cc =>
                            <Column caption={cc.caption} width={cc.width ?? 'fit-content'}
                                cellRender={cc.cellRender}
                                {...cc.dxProps}
                            />)
                        }

                        {this.props.buttonColumns?.filter(x => !x.locateAtBefore)?.map(b => b &&
                            <Column caption={b.caption} width={b.width ?? 'fit-content'}
                                cellRender={c => (b.useButton ?
                                    <Button width={b.width ?? '100%'} rowData={c.data}
                                        {...b.props}
                                        hint={b.hintOfRow?.(c.data) ?? b.hint}
                                        icon={b.iconOfRow?.(c.data) ?? b.icon}
                                        text={b.textOfRow?.(c.data) ?? b.text}
                                        disabled={b.disableIfRow?.(c.data) === true}
                                        className='ka-no-padding' style={{ ...this.props.buttonStyle }}
                                        showBorders={false}
                                        onClick={e => b.onClick?.(c.data)}
                                    /> // onClick={e => b.onClick?.(e.component._props.rowData)}
                                    :
                                    <i className={'dx-icon-' + (b.iconOfRow?.(c.data) ?? b.icon) + ' ka-no-padding'}
                                        style={btnCellStyle} onClick={e => b.onClick?.(c.data)}
                                    >{b.textOfRow?.(c.data) ?? b.text}
                                    </i>)
                                }>
                            </Column>
                        )}

                    </DataGrid>
                }

                {this.props.contextMenuItems?.length > 0 &&
                    <ContextMenu rtlEnabled={!this.props.ltr}
                        key={this.state.focusedRow?.id}
                        dataSource={this.state.contextMenuItems}
                        width={200}
                        target={this.props.id ?? "#uniGridContainer_" + this.sid}
                        onItemClick={e => e.itemData?.command?.(this.state.focusedRow)}
                    />
                }
            </div>
        );
    }
}
