import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import Inline from '../Inline';
import MinimalButton from '../MinimalButton';
// import Space from '../components/Space';

class CollapsibleBox extends Component {
    state = {
        opened: this.props.opened !== false
    };

    render() {
        return (
            <Collapsible open={this.state.opened} width={this.props.width ?? '100%'}
                triggerSibling={
                    <Inline spacing='1rem' rtlEnabled={this.props.rtlEnabled} width={this.props.width ?? '100%'}
                        style={{
                            marginTop: '1rem', background: 'whitesmoke',
                            padding: '1rem',
                            borderRadius: this.state.opened ? '1rem 1rem 0 0' : '1rem',
                            ...this.props.headerStyle
                        }}
                        farItems={
                            !this.props.farItems
                                ? undefined
                                : !Array.isArray(this.props.farItems) || this.props.farItems.length < 2
                                    ? <div style={{ margin: '1rem' }}>{this.props.farItems}</div>
                                    : this.props.farItems
                        }
                    >
                        <MinimalButton icon={this.state.opened ? 'chevronup' : 'chevrondown'}
                            onClick={() => this.setState({ opened: !this.state.opened })} />

                        <div style={{
                            height: '100%', display: 'flex', justifyContent: 'center', fontWeight: '700',
                            ...this.props.titleStyle
                        }} className={this.props.titleClass}
                        >
                            {this.props.title}
                            {/* {this.props.children?.length > 0 ? this.props.children.filter(x => x.type.name === 'Title') : <></>} */}
                        </div>
                    </Inline>
                }

                onOpening={e => {
                    this.setState({
                        borderRadius: '1rem 1rem 0 0',
                        icon: 'chevronup'
                    });
                    this.props.onOpening?.(e);
                }}

                onClosing={e => {
                    this.setState({
                        borderRadius: '1rem',
                        icon: 'chevrondown'
                    });
                    this.props.onClosing?.(e);
                }}

                {...this.props.collapsibleProps}
            >
                <div style={{
                    borderRadius: this.state.open ? '0 0 1rem 1rem' : '1rem', marginTop: '0',
                    width: `${this.props.width ?? '100%'}`,
                    padding: '0 1rem 1rem 1rem',
                    ...this.props.bodyStyle
                }}
                    class={this.props.bodyClass}
                >
                    {this.props.body}
                    {this.props.children}
                </div>
            </Collapsible>
        );
    }
}

export default CollapsibleBox;