/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import Menu, { Item as MenuItem } from "devextreme-react/menu";
//import SelectBox from 'devextreme-react/select-box';
//import CheckBox from 'devextreme-react/check-box';

//import "./my-menu.css";
import { apiGet } from "../../GanjiTools";
import { ScrollView, TabPanel } from "devextreme-react";
import CommandManager from "../CommandManager";

import Header from "./Header";
import { isMobile } from "react-device-detect";

export class TopMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            loading: true,
            cmKey: 1,
        };
    }

    findMenuItems = (condition, within, all = true) => {
        if (!(within?.length > 0) || !condition)
            return undefined;

        let list = [];

        for (let item of within) {
            if (condition(item)) {
                if (!all)
                    return item;

                list.push(item);
            }

            let res = this.findMenuItems(condition, item.items, all);
            if (all) {
                if (res?.length > 0)
                    list = [...list, ...res];
            }
            else if (res)
                return res;
        }
        return all ? list : undefined;
    };

    findMenuItem = id => {
        return id > 0 ? this.findMenuItems(x => x.id === id, this.state.items, false) : undefined;
    }

    assignCommand = menuItem => {
        menuItem.url = menuItem.cmd ?? menuItem.path ?? menuItem.url;

        if (menuItem.items?.length > 0)
            for (let sub of menuItem.items)
                this.assignCommand(sub);
        else {
            menuItem.onClick = item => {
                console.log('>> menu item clicked ', item?.itemData);
                CommandManager.process(item.itemData?.url, item.itemData);
                //this.props.onItemClick?.(menuItem);
            };
        }
    };

    onLoaded = e => {
        let fav = this.findMenuItems(x => x.isfavorite === true, this.state.items, true);
        fav?.forEach(Header.addShortcut);
    };

    componentDidMount() {
        //alert('>> dim: ' + window.innerWidth + ' x ' + window.innerHeight);
        this.state.items = [];
        //apiGet('/api/uni/saveCap', r => console.log('++++', r), err => console.log('++++', err));
        apiGet(`/api/userAccess/topMenu/${window.user?.id ?? 0}`, //'/api/menu/items', /*@@*/
            res => {
                if (res.error) {
                    console.log("error loading top menu items:", res.error);
                    return;
                }
                let items = res?.list;
                //console.log('top menu items: ', items);
                if (items?.length > 0)
                    items.forEach(this.assignCommand);

                this.setState({ items, loading: false }, this.onLoaded);
            }
        );
    }

    onMenuItemRendered = (mi) => {
        mi.itemElement.id = "topmenu__" + mi.itemData?.id;
        return;

        // let onmouseenter = (e) => {
        //     if (!(mi.itemData?.items?.length > 0)) {
        //         this.state.enteredMenuItem = "#" + e.target.id;
        //         this.forceUpdate();
        //     }
        // };

        // mi.itemElement.onmouseenter = mi.element.onmouseenter = onmouseenter;

        // onkeyup = (e) => {
        //     if (e.key !== "s")
        //         return;

        //     let leri = this.state.enteredMenuItem.split("__");
        //     if (leri.length !== 2)
        //         return;

        //     let id = Number(leri[1]);
        //     let m = this.findMenuItem(id);

        //     if (m) {
        //         TopMenu.setFavoriteMenu(m);
        //         // apiPostNoBody('/api/userAccess/favoriteMenu/add/' + id,
        //         //     res => {
        //         //         if (res.error?.length > 0)
        //         //             window.notif(res.error);
        //         //         else
        //         //             Header.addShortcut(m);

        //         //         if (res.warning?.length > 0)
        //         //             console.log(res.warning);
        //         //     },
        //         //     err => window.notif("Error: ", err)
        //         // );
        //     }
        // };

        // mi.element.onkeydown = mi.itemElement.onkeydown = onkeyup;

        //mi.itemElement.onkeydown = console.log;
        //mi.items?.forEach(x =>x.element.onmouseenter = console.log);
        //console.log(mi.itemElement);
        //this.setState({ cmKey: this.state.cmKey + 1 });
    };

    render() {
        if (!(this.state.items?.length > 0))
            return <></>;
        // if (this.state.loading === true) //!(this.state.items?.length > 0))
        //     return (<LoadIndicator />);

        return (//this.state.items.length > 0 &&
            <TabPanel className='top_menu_tabpanel'
                swipeEnabled
                dataSource={this.state.items}
                animationEnabled={true}
                rtlEnabled={true}
                itemTitleRender={(x) => (
                    <span style={{ margin: "0px 0px", padding: "0" }}>{x.text}</span>
                )}
                height={65}
                width={isMobile ? '100vw' : window.innerWidth} //'100vw'
                showNavButtons={true}
                scrollByContent={true}
                noDataText=""
                itemRender={(x) => (
                    <ScrollView rtlEnabled direction='horizontal' scrollByContent scrollByThumb showScrollbar='never'
                        width={isMobile ? '100vw' : window.innerWidth}//'100vw'
                    >
                        <Menu
                            rtlEnabled={true}
                            showFirstSubmenuMode="onHover" //animationEnabled={true}
                            showSubmenuMode="onHover"
                            {...this.props}
                            displayExpr="text"
                            fontSize="11px"
                            dataSource={x.items}
                            noDataText=""
                            hoverStateEnabled
                            width='100%' orientation='horizontal' //  adaptivityEnabled
                            className="ka_top_menu_1"
                            onItemRendered={this.onMenuItemRendered}
                        />
                    </ScrollView>
                )}
            //onContentReady={this.onTabsReady}
            >
            </TabPanel >
        );
    }
}


/*
x.items?.map(i => <Item text={i.text} items={i.items} key={i.id} />)}  </Menu>
---------------------------
<Menu dataSource={this.state.items} {...this.props} displayExpr='label' rtlEnabled={true}
                    style={{ fontSize: '11px' }} width='90vw'
                    activeStateEnabled={true} adaptivityEnabled={true} orientation='horizontal' />
*/
