import React, { Component } from 'react';
import { apiGetP, apiPostNoBodyP, apiPostP } from '../../GanjiTools';
import { SelectBox } from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';
import Lookup, { DropDownOptions } from 'devextreme-react/lookup';
import { Form, Button, TagBox, TextArea, TextBox, DropDownBox, List, Switch } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import { TabPanel, Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { ButtonItem, ButtonOptions, Label, SimpleItem } from 'devextreme-react/form';
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box';
import { TreeList, ColumnHeaderFilter, SearchPanel, Toolbar, Item as ToolbarItem } from 'devextreme-react/tree-list';
import PropSheets from './PropSheets';
import AspNetData from 'devextreme-aspnet-data-nojquery';
import MinimalButton from '../MinimalButton';
import Inline from '../Inline';
import { DataGrid, Grouping, Column, GroupPanel } from 'devextreme-react/data-grid';
import VerifyingGrid from './VerifyingGrid';
import SelectBySwitches from '../../utils/SelectBySwitches';
import RiskMatrix from './RiskMatrix';

// import {} from '@fortawesome/free-regular-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class RbiHome extends Component {
    state = {
        //hid: this.props.hid ?? 0,
        components: [],
        //component: this.props.component,
        allDfs: [],
        //assetDfs: this.props.assetDfs ?? [1],
        assetsTreeKey: 1,
        applicableDfs: [],
        assetPopKey: 1,
        tabsKey: 1,
        dfKey: 1,
        calcResult: { error: 'Not calculated yet.' },
        //resultPopVisible: false,
    };

    loadFixedDataP = () => (
        [
            new Promise((resolve, reject) => {
                apiGetP('/api/rbi/components').catch(reject).then(resolve);
            }),
            new Promise((resolve, reject) => {
                apiGetP(`/api/rbi/damageFactorsAll`).catch(reject).then(resolve);
            }),
        ]
    );

    loadAssetDataP = assetId => (
        [
            new Promise((resolve, reject) => {
                if (!(assetId > 0)) {
                    resolve([]);
                    return;
                }
                apiGetP(`/api/rbi/inputs?hid=${assetId}&groups=basicInfo`).catch(reject).then(resolve);
            }),
            new Promise((resolve, reject) => {
                if (!(assetId > 0)) {
                    resolve([]);
                    return;
                }
                apiGetP(`/api/rbi/damageFactors?assetId=${assetId}`).catch(reject).then(resolve);
            }),
        ]
    );

    loadAssetDfsP = assetId => new Promise((resolve, reject) => {
        if (!(assetId > 0)) {
            resolve([]);
            return;
        }

        apiGetP(`/api/rbi/damageFactors?assetId=${assetId}`).catch(reject).then(resolve); // { error, assetDfs, applicableDfs }
    });

    loadAssetsP = () => apiGetP('/api/rbi/assets');

    //loadApplicableDfsP = assetId => apiGetP('/api/rbi/applicableDfs?assetId=' + assetId); // returns { error, applicableDfs, asset }
    loadApplicableDfsByCompoIdP = compoId => apiGetP('/api/rbi/applicableDfsByCompoId?compoId=' + compoId); // returns { error, applicableDfs, asset }

    checkConnectionP = () => apiGetP('/api/rbi/checkConnection');

    componentDidMount() {
        this.checkConnectionP()
            .catch(() => this.setState({ connectionOk: false }))
            .then(res => {
                let connectionOk = res === 'ok';
                this.state.connectionOk = connectionOk;

                if (connectionOk)
                    Promise.all([this.loadAssetsP(), ...this.loadFixedDataP()])
                        .catch(window.notif)
                        .then(([assets, components, allDfs]) => {
                            //list = list?.sort((a, b) => (a.equipId - b.equipId) * 1000 + a.compoId - b.compoId) ?? [];
                            this.state.assets = assets;
                            this.state.components = components;
                            this.state.allDfs = allDfs;
                            // this.forceUpdate();
                            //this.onHidChanged(this.props.hid);
                            //this.setState({ assets, components, allDfs });
                        })
                        .finally(() => this.forceUpdate());
                else
                    this.forceUpdate();
            });
    }


    // ds = {
    //     async load(loadOptions) {
    //         const parentIdsParam = loadOptions.parentIds;
    //         const url = new URL('https://localhost:7018/api/rbi/assets');
    //         if (parentIdsParam?.length > 0) {
    //             parentIdsParam.forEach((id) => {
    //                 url.searchParams.append('parentIds', id);
    //             });
    //         }
    //         const result = await fetch(url.toString());
    //         if (result.status === 200) {
    //             return result.json();
    //         }
    //         throw new Error('Data Loading Error');
    //     },
    // };

    // ds1 = AspNetData.createStore({
    //     key: 'id',
    //     loadUrl: 'https://localhost:7018/api/rbi/assets',
    //     // insertUrl: `${url}/InsertTask`,
    //     // updateUrl: `${url}/UpdateTask`,
    //     // deleteUrl: `${url}/DeleteTask`,
    //     onBeforeSend(method, ajaxOptions) {
    //         ajaxOptions.xhrFields = { withCredentials: true };
    //     },
    // });

    // ds2 = AspNetData.createStore({
    //     key: 'id',
    //     loadUrl: 'https://localhost:7018/api/rbi/assets',
    // });

    onFocusedAssetChanged = row => {
        this.state.focusedAsset = row;
        this.state.parentAssetId = row?.id;
        this.state.applicableDfs = [];
        this.state.assetDfs = [];
        this.state.calcResult = [];
        this.state.dfKey++;

        if (!(row?.id > 0)) {
            this.forceUpdate();
            return;
        }
        //this.state.tabsKey++;

        this.loadAssetDfsP(row.id)
            .catch(window.notif)
            .then(res => {
                let { error, assetDfs, applicableDfs, applicableAssetDfs } = res ?? { error: 'No response.', assetDfs: [], applicableDfs: [], applicableAssetDfs: [] };

                applicableDfs = applicableDfs.map(x => ({ ...x, isApplicable: true }));
                //applicableDfs = [...applicableDfs, ...assetDfs.filter(x => !applicableDfs.find(f => f.id === x.id))];

                if (error?.length > 0) {
                    window.notif(error);
                    return;
                }

                console.log('=== focused asset -> ', row);
                console.log('=== applicabeDfs: ', applicableDfs);
                console.log('=== assetDfs: ', assetDfs);
                this.state.applicableDfs = applicableDfs;
                this.state.assetDfs = assetDfs; // applicableAssetDfs

                // this.state.applicableDfs = applicableDfs;
                // this.state.assetDfs = assetDfs;
                // this.forceUpdate();

            }).finally(() => this.forceUpdate());

        //this.setState({ focusedAsset: e.row?.data, parentAssetId: e.row?.data?.id });
    }

    // onNewAssetAdded = newAssetId => {
    //     this.setState({ hid: newAssetId, assetsTreeKey: this.state.assetsTreeKey + 1 });
    // };

    // onDfidsChanges = dfids => {
    //     this.setState({ dfids, sheetKey: this.state.sheetKey + 1 });
    // }

    // onAssetSwitched = hid => {
    //     this.props.onAssetSwitched?.(hid);
    // }

    // onDfidsEdited = dfids => {
    //     this.props.onDfidsEdited?.(dfids);
    // }

    // onNewAssetAdded = compoId => {
    //     this.props.onDfidsEdited?.(compoId);
    // }

    basicInfoAsText = () => {
        let bi = this.state.basicInfo;
        if (!bi)
            return '';

        return `${bi.equipmentType?.split('|')?.[1]} - ${bi.componentType?.split('|')?.[1]} ${(bi.isAtmosphericTank ? 'AT' : '')}`;
    }

    // report = newlyAddedAssetId => {
    //     if (newlyAddedAssetId > 0) {
    //         this.props.onNewAssetAdded?.(newlyAddedAssetId);
    //         return;
    //     }

    //     this.props.onSelected?.({
    //         hid: this.state.hid ?? 0,
    //         component: this.state.component ?? 0,
    //         dfids: this.state.assetDfs ?? [1]
    //     });
    // }

    // onClickOnGo = () => {
    //     if (this.state.hid > 0) {
    //         this.report();
    //         return;
    //     }

    //     if (!(this.state.component > 0))
    //         return;

    //     apiPostNoBodyP(`/api/rbi/newAssetByDfs?compoType=${this.state.component}&dfidsJsn=${this.state.assetDfs?.length > 0 ? JSON.stringify(this.state.assetDfs) : '[1]'}`)
    //         .catch(window.notif)
    //         .then(res => {
    //             let { error, /*applicableDfs,*/ assetId } = res ?? { error: 'No response.' };

    //             if (error?.length > 0 || !(assetId > 0))
    //                 window.notif(error);
    //             else
    //                 this.report(assetId);
    //             //this.setState({ hid: assetId, applicableDfs }, () => this.report(assetId));
    //         });
    // };

    checkAssetDfs = dfids => {
        let assetDfs = dfids ?? [1];

        if (!assetDfs.includes(1))
            assetDfs = [1, ...assetDfs];

        return assetDfs;
    };


    calculate = assetId => {
        if (!(assetId > 0))
            return;

        //        this.setState({focusedAsset})

        apiGetP(`/api/rbi/calc?assetId=${assetId}`)
            .catch(err => this.setState({ calcResult: { error: err?.toString() }, resultPopVisible: true }))
            .then(res => this.setState({ calcResult: res ?? { error: 'No responce.' } }, () => this.setState({ resultPopVisible: true })));
    }

    makeAssetCopy = assetId => {
        if (assetId > 0)
            apiPostNoBodyP('/api/rbi/addAssetCopy?assetId=' + assetId)
                .catch(window.notif)
                .then(res => {
                    let { newAssetId, error } = res ?? { error: 'No response.' };
                    if (error?.length > 0)
                        window.notif(error);
                    else if (newAssetId > 0) {
                        this.loadAssetsP()
                            .catch(window.notif)
                            .then(assets => {
                                assets ??= [];
                                //list = list?.sort((a, b) => (a.equipId - b.equipId) * 1000 + a.compoId - b.compoId) ?? [];
                                this.state.assets = assets;
                                //this.state.assetsTreeKey++;
                                // this.forceUpdate();
                                //this.onHidChanged(this.props.hid);
                                //this.setState({ assets, components, allDfs });
                            })
                            .finally(() => this.forceUpdate());
                    }
                });
    }

    renderCommandsCell = c => {
        let focusedAsset = c.row?.data;

        return (
            <Inline spacing={10}>
                <MinimalButton icon='plus'
                    onClick={() => this.setState({ focusedAsset: { id: 0 }, assetPopVisible: true, assetPopupReadonly: false })}
                />
                <MinimalButton icon='edit' onClick={e => this.setState({ assetPopVisible: true, assetPopupReadonly: false })} />
                <MinimalButton icon='info' onClick={e => this.setState({ assetPopVisible: true, assetPopupReadonly: true })} />
                <MinimalButton icon='copy' onClick={e => this.makeAssetCopy(focusedAsset?.id)} />
                {/* <MinimalButton icon='trash' color='red' onClick={e => { }} /> */}
                <MinimalButton onClick={() => this.setState({ focusedAsset }, () => this.calculate(focusedAsset?.id))}>
                    Calculate
                </MinimalButton>
            </Inline>
        );
    }

    onSaveAsset = (alsoCompoId, keepPopupOpen) => {
        let a = this.state.focusedAsset;

        if (!(a?.id >= 0))
            return;

        let url = `/api/rbi/asset?assetId=${a.id}&name=${a.name}&dfidsJsn=${a.activeDfNumbers ?? '[1]'}&parentId=${a.id > 0 ? a.fK_Parent : this.state.parentAssetId}`;

        if (alsoCompoId === undefined)
            alsoCompoId = a.id === 0;

        if (alsoCompoId)
            url += `&compoId=${a.compoId}`;

        apiPostNoBodyP(url)
            .catch(window.notif)
            .then(res => {
                let { error, asset } = res ?? { error: 'No responce.' };
                if (error?.length > 0 || !(asset?.id > 0))
                    window.notif(error);
                else
                    this.loadAssetsP()
                        .catch(window.notif)
                        .then(assets => {
                            if (keepPopupOpen === true)
                                this.setState({ assets, focusedAsset: asset });
                            else
                                this.setState({ assets, focusedAsset: asset, assetPopVisible: false });
                        });
            });
    }

    postDfs = () => {
        let a = this.state.focusedAsset;
        if (!(a?.id > 0))
            return;

        let url = `/api/rbi/asset?assetId=${a.id}&name=<keep>&dfidsJsn=${a.activeDfNumbers ?? '[1]'}&parentId=-1`;

        apiPostNoBodyP(url)
            .catch(window.notif)
            .then(res => {
                let { error, asset, applicableDfs } = res ?? { error: 'No responce.' };
                if (error?.length > 0)
                    window.notif(error);
                else {
                    this.onFocusedAssetChanged(this.state.focusedAsset);
                    //this.setState({ assetDfs: JSON.parse(asset.activeDfNumbers ?? '[1]') })
                    console.log('dfids are successfully saved to db. asset=', asset, ' ; applicable dfs=', applicableDfs);
                }
            });
    }

    onDamageFactorsChanged = dfids => {
        if (this.state.focusedAsset) {
            this.state.focusedAsset.activeDfNumbers = JSON.stringify(dfids);
            //this.forceUpdate();
        }
    }

    onComponentChanged = e => {
        if (this.state.focusedAsset)
            this.state.focusedAsset.compoId = e.value;

        this.state.applicableDfs = [];

        if (!(this.state.focusedAsset?.id > 0)) {
            this.loadApplicableDfsByCompoIdP(e.value)
                .catch(window.notif)
                .then(res => {
                    let { error, applicableDfs } = res ?? { error: 'No response.' };
                    if (error?.length > 0)
                        window.notif(error);
                    else
                        this.state.applicableDfs = applicableDfs.map(x => ({ ...x, isApplicable: true }));
                })
                .finally(() => {
                    this.forceUpdate();
                    this.onSaveAsset(true, true);
                });

            this.forceUpdate();
        }
    };

    render() {
        if (this.state.connectionOk !== true)
            return (<h1 style={{ color: 'lightgray', padding: '3rem' }}>Waiting for server...</h1>);

        let components = new DataSource({
            store: {
                type: 'array',
                data: this.state.components,
                key: 'compoId',
            },
            group: 'equipName',
        });

        return (
            <>
                {/* <StepsChain items={[{ text: 'Hello' }, { text: 'Ali' }]} normalBackground='red' highlightedBackground='yellow' /> */}
                {/* <div style={hexagonArrowStyle}
                >
                    AAAA
                </div> */}

                <div style={{ display: 'flex', flexDirection: 'row', width: '99%'/*99vw*/ }}>
                    <div style={{ width: '39vw', padding: '1rem' }}>
                        <TreeList key={'tree' + this.state.assetsTreeKey} dataSource={this.state.assets}
                            parentIdExpr='fK_Parent' keyExpr='id'
                            columnAutoWidth
                            focusedRowEnabled //focusedRowKey={this.state.focusedAsset?.id}
                            onFocusedRowChanged={e => this.onFocusedAssetChanged(e.row?.data)}
                        //width='90%'
                        >
                            {/* <RemoteOperations filtering /> */}
                            {/* <SearchPanel visible={true} /> */}
                            {/* <ColumnHeaderFilter visible={true} /> */}
                            <Toolbar>
                                <ToolbarItem location='after'>
                                    <MinimalButton icon='plus'
                                        onClick={e => this.setState({ parentAssetId: null, focusedAsset: { id: 0 }, assetPopVisible: true, assetPopupReadonly: false })}
                                    />
                                </ToolbarItem>
                                <ToolbarItem text='Assets Tree' location='before'>
                                </ToolbarItem>
                            </Toolbar>
                            <Column name='name' dataField='name' />
                            <Column name='equipName' dataField='equipName' />
                            <Column name='compoName' dataField='compoName' />
                            <Column name='addEditAsset' cellRender={this.renderCommandsCell} />
                        </TreeList>
                    </div>

                    <div style={{ width: '57vw', padding: '1rem' }}>
                        <div style={{ fontSize: '20px', padding: '5px 0 10px 0' }}>Asset Other Properties</div>
                        <TabPanel key={'tabs_' + this.state.tabsKey + '_' + (this.state.focusedAsset?.id ?? 0)}>
                            <TabPanelItem title='General' key={`tbpi_${this.state.focusedAsset?.id ?? 0}`}>
                                <PropSheets key={`ps_${this.state.focusedAsset?.id ?? 0}_general`}
                                    asset={this.state.focusedAsset} showGeneral
                                //onDataChanged={() => this.onFocusedAssetChanged(this.state.focusedAsset)}
                                />
                            </TabPanelItem>

                            <TabPanelItem title='Damage Selection'>
                                <div style={{ padding: '1rem' }}>
                                    <SelectBySwitches dataSource={this.state.applicableDfs}
                                        selectedKeys={this.state.assetDfs?.map(x => x.id)}//{JSON.parse(this.state.focusedAsset?.activeDfNumbers ?? '[1]')}
                                        onSelectedKeysChanged={keys => { this.onDamageFactorsChanged(keys); this.postDfs(); }}
                                    />
                                </div>
                            </TabPanelItem>

                            <TabPanelItem title='Damage Properties'>
                                <PropSheets key={`ps_${this.state.dfKey}_${this.state.focusedAsset?.id}_dfp`}
                                    asset={this.state.focusedAsset} showAllDfs
                                />
                            </TabPanelItem>

                            <TabPanelItem title='Consequence'>
                                <PropSheets key={'ps_' + this.state.focusedAsset?.id + '_cof'}
                                    asset={this.state.focusedAsset} showCof
                                />
                            </TabPanelItem>

                            <TabPanelItem title='Results'>
                                <RiskMatrix assetId={this.state.focusedAsset?.id ?? 0} />
                            </TabPanelItem>

                        </TabPanel>

                    </div>
                </div>

                <Popup key={'assetPopup_' + JSON.stringify(this.state.assetDfs)} visible={this.state.assetPopVisible} //key={'pop' + this.state.assetPopKey}
                    title='Asset' width='60%' height='auto'
                    showCloseButton onHidden={() => this.setState({ assetPopVisible: false })}
                    onShowing={() => console.log('---- popup asset =', this.state.focusedAsset, this.state.assetPopKey)}
                >
                    <Form colCount={60} width='100%' key={'form_' + this.state.focusedAsset?.id}
                    >
                        <SimpleItem colSpan={15}>
                            <Label text='Asset Id' />
                            <TextBox value={this.state.focusedAsset?.id} readOnly />
                        </SimpleItem>

                        <SimpleItem colSpan={15}>
                            {(this.state.focusedAsset?.id > 0 && (this.state.focusedAsset.compoId > 0 || this.state.focusedAsset.equipId > 0 || this.state.assetPopupReadonly)
                                ?
                                <TextBox text={this.state.focusedAsset.equipName + " || " + this.state.focusedAsset?.compoName} readOnly />
                                :
                                <SelectBox dataSource={components} displayExpr='compoName' valueExpr='compoId'
                                    value={this.state.focusedAsset?.compoId}
                                    onValueChanged={this.onComponentChanged}
                                    grouped groupRender={x => (
                                        <div style={{
                                            textAlign: 'center', padding: '5px', fontWeight: '700',
                                            margin: '0', background: '#666699', color: 'white'
                                        }}
                                        >
                                            {x.key}
                                        </div>
                                    )}
                                />
                            )}
                        </SimpleItem>

                        <SimpleItem colSpan={30} >
                            <Label text='Name' />
                            <TextBox value={this.state.focusedAsset?.name} readOnly={this.state.assetPopupReadonly}
                                onValueChanged={e => this.setState({ focusedAsset: { ...this.state.focusedAsset, name: e.value } })}
                            />
                        </SimpleItem>

                        <SimpleItem colSpan={60} >
                            <Label text='Damage Factors' />
                            <TagBox dataSource={this.state.applicableDfs} readOnly={true || this.state.assetPopupReadonly}//?.length > 0 ? this.state.applicableDfs : this.state.allDfs}
                                // dataSource={this.state.applicableDfs}
                                placeholder='Thinning' multiline
                                //displayExpr={x => x.summary + (!(this.state.focusedAsset?.id > 0) || x.isApplicable ? '' : ' 🛇')}
                                displayExpr={x => x.summary + (!(this.state.focusedAsset?.id > 0) || x.isApplicable ? '' : ' 🛇')}
                                //value={this.state.assetDfs}
                                value={this.state.assetDfs} //JSON.parse(this.state.focusedAsset?.activeDfNumbers ?? '[1]')}
                                valueExpr='id'
                                //onValueChanged={e => this.onDamageFactorsChanged(e.value)}
                                //onSelectionChanged={console.log}
                                showClearButton hideSelectedItems
                            />
                            {/* <SelectBySwitches dataSource={this.state.applicableDfs}
                                selectedKeys={this.state.assetDfs?.map(x => x.id)}//{JSON.parse(this.state.focusedAsset?.activeDfNumbers ?? '[1]')}
                                onSelectedKeysChanged={keys => { this.onDamageFactorsChanged(keys); this.postDfs(); }}
                            /> */}

                        </SimpleItem>

                        <SimpleItem colSpan={60} template={'<br/>'} />

                        <ButtonItem colSpan={15}>
                            <ButtonOptions text={this.state.assetPopupReadonly ? 'Close' : 'OK'} width='15rem' type='success'
                                onClick={this.state.assetPopupReadonly ? () => this.setState({ assetPopVisible: false })
                                    : () => this.onSaveAsset()}
                            />
                        </ButtonItem>
                    </Form>
                </Popup >

                <Popup visible={this.state.resultPopVisible} title='Results' key={'respop_' + this.state.focusedAsset?.id}
                    showCloseButton
                    width='90%' height='auto'
                    onHidden={() => this.setState({ resultPopVisible: false })}
                >
                    <div style={{ width: '100%', padding: '1rem' }}>
                        {this.state.calcResult.error?.length > 0 &&
                            <>
                                <div style={{ color: 'darkred', fontWeight: '900' }}>Errors: </div>
                                {this.state.calcResult.error.split('|').map(x => x.trim().replace(';', '')).filter(x => x.length > 2).map(x => <li style={{ marginLeft: '1rem', color: 'darkred' }}>{x.replace(';', '')}</li>)}
                                <br />
                            </>
                        }

                        <div>PoF = {this.state.calcResult.pof}  @  {this.state.calcResult.time}</div>
                        <div>CoF Area = {this.state.calcResult.cof_a}   ⯁   CoF Financial = {this.state.calcResult.cof_f}</div>
                        <div>Risk Area = {this.state.calcResult.risk_a}   ⯁   Risk Financial = {this.state.calcResult.risk_f}</div>
                        <br />
                        <TabPanel key={'tp' + this.state.focusedAsset?.id}>
                            {this.state.calcResult.testLogs_Cat &&
                                Object.keys(this.state.calcResult.testLogs_Cat).map(oc => (
                                    <TabPanelItem title={oc} key={'tpi' + this.state.focusedAsset?.id + '_' + oc}>
                                        <VerifyingGrid ds={this.state.calcResult.testLogs_Cat?.[oc]} assetId={this.state.focusedAsset?.id} cat={oc} />
                                    </TabPanelItem>
                                ))
                            }
                        </TabPanel>
                    </div>
                </Popup>
            </>
        );
    }
}

export default RbiHome;