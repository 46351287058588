import { Accordion, Drawer, List } from 'devextreme-react';
import { TabPanel, Item as TabPanelItem } from 'devextreme-react/tab-panel';
import React, { Component } from 'react';
import MinimalButton from '../MinimalButton';
import Inline from '../Inline';
import RbiHome from '../rbi/RbiHome';
import './PSM_Layout.scss';

const menuItems = [
    'Process Safety Information (PSI)',
    'Employee Participation',
    'Process Hazard Analysis (PHA)',
    'Operating Procedures',
    'Management of Change (MOC)',
    'Hot Work Permit',
    'Mechanical Integrity',
    'Emergency Planning & Response',
    'Pre-Startup Safety Review (PSSR)',
    'Incident Investigation',
    'Compliance Audits',
    'Contractors',
    'Training',
    'Trade Secrets'
];

class PSM_Layout extends Component {

    state = {
        opened: true,
        body: <h1 style={{ opacity: '10%', padding: '3rem' }}>PSM Software</h1>,
    };

    renderRbi = () => {
        return (
            <TabPanel //height='calc(100vh - 5rem)'
            >
                <TabPanelItem title='RBPSM'>
                    <RbiHome />
                </TabPanelItem>
            </TabPanel>
        );
    }

    onItemClick = item => {
        this.setState({ body: item.id === 2 ? this.renderRbi() : <h1 style={{ opacity: '10%', padding: '3rem' }}>{item.text}</h1> })
    }

    render() {
        return (
            <div style={{ padding: '10px' }}>
                <Inline spacing='10px' style={{ padding: '1rem 0.5rem', background: 'lavender' }}>
                    <MinimalButton icon='menu' width='auto'
                        iconStyle={{ fontSize: '24px' }}
                        onClick={() => this.setState({ opened: !this.state.opened })}
                    />
                    <p style={{ fontSize: '20px', fontWeight: '800', margin: '0' }}>Process Safety Management</p>
                </Inline>
                {/* 
                <Accordion height='calc(100vh - 4rem)' width='20rem'
                    items={menuItems.map((text, id) => ({ id, text, items: id === 2 ? ['RBPSM'] : '' }))}
                    collapsible hoverStateEnabled keyExpr='id'
                    itemTitleRender={i => i.text}
                    itemRender={i => i.items?.length > 0 ? <List items={i.items} /> : <></>}
                /> */}

                <Drawer height='calc(100vh - 5rem)'
                    render={() => <DrawerItems onItemClick={this.onItemClick} />}
                    opened={this.state.opened} openedStateMode='shrink'
                >
                    <div style={{
                        padding: '1rem', width: `calc(100vw - ${this.state.opened ? 22 : 2}rem)`,
                        //height: 'calc(100vh - 4rem)', overflowY: 'scroll'
                    }}>
                        {this.state.body}
                    </div>
                </Drawer>
            </div>
        );
    }
}

class DrawerItems extends Component {
    render() {
        return (
            <div className='list' style={{ width: '20rem' }}>
                <List dataSource={menuItems.map((text, id) => ({ id, text }))}
                    // itemRender={i => <div style={{}}>{i.text}</div>}
                    className='left-list' //'panel-list dx-theme-typography-background-color'
                    onItemClick={e => this.props.onItemClick?.(e.itemData)}
                />
            </div>
        );
    }
}

export default PSM_Layout;